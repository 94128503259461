// Override default variables before the import
// $body-bg: #000;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import 'assets/css/variables';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
// @font-face {
//   font-family: 'Larsseit';
//   src: url('./assets/fonts/larsseit/Larsseit.woff2') format('font-woff2'),
//     url('./assets/fonts/larsseit/Larsseit.woff') format('font-woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Larsseit';
//   src: url('./assets/fonts/larsseit/Larsseit-Medium.woff2') format('font-woff2'),
//     url('./assets/fonts/larsseit/Larsseit-Medium.woff') format('font-woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Larsseit';
//   src: url('./assets/fonts/larsseit/Larsseit-Bold.woff2') format('font-woff2'),
//     url('./assets/fonts/larsseit/Larsseit-Bold.woff') format('font-woff');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Larsseit';
//   src: url('./assets/fonts/larsseit/Larsseit-ExtraBold.woff2') format('font-woff2'),
//     url('./assets/fonts/larsseit/Larsseit-ExtraBold.woff') format('font-woff');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Larsseit';
//   src: url('./assets/fonts/larsseit/Larsseit-Light.woff2') format('font-woff2'),
//     url('./assets/fonts/larsseit/Larsseit-Light.woff') format('font-woff');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Larsseit';
//   src: url('./assets/fonts/larsseit/Larsseit-Thin.woff2') format('font-woff2'),
//     url('./assets/fonts/larsseit/Larsseit-Thin.woff') format('font-woff');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }
@font-face {
  font-family: 'icomoon';
  src: url('../src/assets/fonts/icomoon/icomoon.eot?i4usms');
  src: url('../src/assets/fonts/icomoon/icomoon.eot?i4usms#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/icomoon/icomoon.ttf?i4usms') format('truetype'),
    url('../src/assets/fonts/icomoon/icomoon.woff?i4usms') format('woff'),
    url('../src/assets/fonts/icomoon/icomoon.svg?i4usms#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-curbside:before {
  content: '\e900';
  //   color: var(--highlight-text-color);
}
.icon-delivery_icon:before {
  content: '\e901';
  //   color: var(--highlight-text-color);
}
.icon-dine_in:before {
  content: '\e902';
  //   color: var(--highlight-text-color);
}
.icon-pick_up:before {
  content: '\e903';
  //   color: var(--highlight-text-color);
}

html {
  scroll-behavior: auto !important;
  max-width: $max-width;
  margin: auto;
  background-color: #f5f5f5;
  height: 100%;
}

* {
  font-family: 'Source Sans 3', sans-serif;
}

input {
  font-family: 'Source Sans 3', sans-serif !important;
}

body {
  font-family: 'Source Sans 3', sans-serif;
  height: 100%;
  overflow-x: hidden;
  padding-right: 0px !important;
  // padding-left: 8px;
  // width: calc(100vw - 8px);
  // @media screen and (min-width: 1058px) {
  //   body {
  //       padding-left: 8px;
  //       width: calc(100vw - 8px);
  //   }
  // }
}

body.modal-open {
  overflow: hidden;
}

#root {
  height: auto;
  min-height: 100%;
  background-color: #ffffff;
  display: flex;
  //overflow-x: hidden !important;
}

.app-container {
  width: 100%;
}

.horizontal-scroll {
  min-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  scrollbar-width: none;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.badge-success {
  color: #fff;
}

.no-padding {
  padding: 0 !important;
}

.text-bold {
  font-weight: bold !important;
}

.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2392D14F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.listmenu:hover {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
}

.align-text-end {
  text-align: end !important;
}

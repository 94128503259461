.sub-modal-m .modal-content {
  min-height: 400px;
}

.sub-modal-a {
  padding: 50px;
  padding-bottom: 100px;
}
.sub-modal-term {
  padding: 50px;
  padding-bottom: 100px;
}

.sub-modal-loyalty-term {
  padding: 50px;
  padding-bottom: 100px;
}

.header-margin-top {
  z-index: 1;
  margin-bottom: -70px;
}

.route-order-type-padding-top {
  padding-top: 60px !important;
}

.route-background-skin {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.route-skip-padding {
  padding-top: 100px;
}

.i-circle {
  width: 120px;
  height: 120px;
  line-height: 110px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  background: var(--primary-color) !important;
  @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
  font-family: 'Lobster', cursive;
}

.i-circle-logo-main {
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: var(--primary-color) !important;
  @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
  font-family: 'Lobster', cursive;
}

@media (min-width: 500px) {
  .order-pref-width {
    min-width: 450px;
    width: auto;
  }
}

.order-pref-modal,
.sub-modal-m,
.sub-modal-t,
.sub-modal-a,
.sub-modal-term,
.sub-modal-loyalty-term {
  max-width: 500px !important;
}

.route-pref-modal,
.sub-modal-m,
.sub-modal-t,
.sub-modal-a,
.sub-modal-loyalty-term {
  max-width: 500px !important;
}

.sub-modal-term {
  max-width: 500px !important;
}

.sub-modal-bin,
.sub-modal-ritems {
  max-width: 900px !important;
}

.sub-modal-t {
  .modal-body {
    overflow: hidden !important;
  }
}

@media (max-width: 500px) {
  .sub-modal-m {
    padding-top: 150px;

    .modal-content {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  .header-margin-top {
    z-index: 1;
    margin-bottom: -80px;
  }

  .sub-modal-t {
    padding-top: 250px;

    .modal-content {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .modal-body {
      overflow: hidden !important;
    }
  }

  .sub-modal-a {
    padding-top: 350px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .modal-content {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  .sub-modal-loyalty-term,
  .sub-modal-term {
    padding-top: 350px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .order-pref-width {
    width: auto !important;
  }

  // @keyframes modalFade {
  //   from {transform: translateY(50%);opacity: 0;}
  //   to {transform: translateY(0);opacity: 1;}
  // }
  // .modal {
  //   animation-name: modalFade;
  //   animation-duration: .3s;
  // }
}

@media (min-width: 501px) {
  .order-pref-modal .modal-content {
    border-radius: 0.8rem !important;
    background: transparent !important;
  }

  .order-pref-modal .modal-header {
    padding-bottom: 10px !important;
  }

  .order-pref-modal .modal-content {
    height: 91% !important;
  }

  .order-pref-modal .modal-footer {
    border: 0px !important;
    border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }

  .route-pref-modal .modal-content {
    border-radius: 0.8rem !important;
    background: transparent !important;
    border: 0px;
  }

  .route-pref-modal .modal-header {
    padding-bottom: 0px !important;
  }

  .route-pref-modal .modal-content {
    height: 91% !important;
  }

  .route-pref-modal .modal-footer {
    border: 0px !important;
    border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }

  .sub-modal-m .modal-content {
    border-radius: 0.8rem !important;
  }

  .sub-modal-m .modal-header {
    padding-bottom: 10px !important;
  }

  .sub-modal-m .modal-content {
    height: 91% !important;
  }

  .sub-modal-t .modal-content {
    border-radius: 0.8rem !important;
  }

  .sub-modal-t .modal-header {
    padding-bottom: 0px !important;
  }

  .sub-modal-t .modal-content {
    height: 91% !important;
  }
  .sub-modal-ritems .modal-content {
    height: 91% !important;
  }
  .sub-modal-loyalty-term .modal-content {
    height: 100% !important;
  }
  .sub-modal-bin .modal-content {
    height: 91% !important;
  }
  .sub-modal-term .modal-content {
    height: 100% !important;
  }

  .sub-modal-a .modal-content {
    border-radius: 0.8rem !important;
  }
}

@import 'assets/css/variables';

$primary-color: var(--primary-color);
$highlight-color: var(----highlight-color);
$highlight-text-color: var(----highlight-text-color);
$text-color: var(----h2-text-color);
$header-text-color: var(----h2-color);

.intro {
  padding: 1rem 1rem 0 1rem;
}

/* Extra Large */
.f0 {
  color: #2e3434;
  font-size: 1.4rem;
  font-weight: bold;
}

/* Large */
.f1 {
  color: #2e3434;
  font-size: 1.35rem;
  font-weight: bold;
}

/* Medium */
.f2 {
  color: #2e3434;
  font-size: 1.1rem;
  font-weight: bold;
}

/* Normal */
.f3 {
  color: #2e3434;
  font-size: 0.95rem;
  font-weight: normal;
}

/* Small */
.f4 {
  color: #2e3434;
  font-size: 0.8rem;
  font-weight: bold;
}

/* Extra Large */
.f0-light {
  color: #2e3434;
  font-size: 1.4rem;
}

/* Large */
.f1-light {
  color: #2e3434;
  font-size: 1.35rem;
}

/* Medium */
.f2-light {
  color: #2e3434;
  font-size: 1.1rem;
}

/* Normal */
.f3-light {
  color: #2e3434;
  font-size: 0.95rem;
}

/* Small */
.f4-light {
  color: #2e3434;
  font-size: 0.8rem;
}

// Utilities
.flex-column-space-between {
  display: flex;
  justify-content: space-between;

  &.center-aligned {
    align-items: center;
  }
}

.text-normal {
  font-weight: normal !important;
}

.grid-image {
  @media (max-width: 450px) {
    padding: 0 !important;
  }
}

// Popular Categories

.popular {
  padding: 1rem;

  .popular-image {
    cursor: pointer;
    display: flex;
    padding-left: 1em;
    height: 165px;
    align-items: flex-end;
    margin-right: 1rem;
    min-width: 230px;
    border-radius: 10px;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    &:last-child {
      margin-right: 0;
    }

    .opaque {
      align-self: flex-end;
      padding-bottom: 1em;
    }

    .popular-item-name {
      color: #fff;
      font-weight: 600 !important;
    }
  }
}

.category-list {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0.5rem 1rem;
  z-index: 1030;
  background-color: #fff;
  align-items: stretch;
  border-radius: 6px;

  .category {
    white-space: nowrap;
    cursor: pointer;
    margin: 5px;
    border-radius: 1.2rem;
    border: none;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    // Disable text selection upon touch and click
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    &.active {
      color: var(--highlight-text-color) !important;
      background-color: var(--highlight-color);
    }
  }

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
}

.items {
  .media {
    padding: 1em;
  }

  .category-name {
    padding: 1rem;
    margin-bottom: 0;
  }

  .item {
    width: 100%;
    cursor: pointer;

    .row {
      width: 100%;
    }

    .item-name {
      font-weight: bold !important;
    }

    .disabled-item {
      opacity: 0.5;
    }

    .item-description {
      font-weight: 500 !important;
      max-height: 50px;
      margin-bottom: 5px;
      padding-top: 5px;
      overflow: hidden;
    }

    .item-description-new {
      font-weight: 500 !important;
      max-height: 50px;
      margin-bottom: 35px;
      padding-top: 5px;
      overflow: hidden;
    }

    .item-price {
      .font-weight-bold {
        font-weight: bold !important;
      }

      font-weight: normal !important;
      margin-right: 1rem;
    }

    .item-image-rating-container {
      float: right;
      text-align: center;
    }

    // .old-image {
    //   margin-right: -24px;
    // }

    .item-image {
      float: right;
      border-radius: 5px;
    }

    &:last-child {
      border-bottom: none;
    }

    &.item-with-quantity {
      border-left: 5px solid var(--primary-color);
    }

    .inline-rating {
      float: right;
      width: 90px;
      text-align: center;
    }

    .rating {
      &.badge {
        position: absolute;
        right: 32px;
      }
    }
  }
}

.items-custom {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;

    .row {
      @media (max-width: 768px) {
        column-gap: 8px;
        row-gap: 20px;

        .col-md-12,
        .col-lg-6 {
          flex: 0 0 48%;
          max-width: 48%;
        }
      }

      @media (max-width: 920px) {
        column-gap: 14px;
        row-gap: 20px;

        .col-md-12,
        .col-lg-6 {
          //flex: 0 0 48%;
          max-width: 48%;
        }
      }
    }
  }

  .media {
    padding: 1em;
  }

  .custom-category-name {
    font-size: 20px;
    // font-weight: 500;
    line-height: 23px;
    position: relative;
    text-transform: uppercase;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 0 !important;

    &:before {
      position: absolute;
      content: '';
      background: var(--highlight-color);
      width: 2px;
      left: 0;
      height: calc(100% - 70px);
    }
  }

  .custom-category-name-1 {
    &:before {
      height: calc(100% - 60px) !important;
    }

    @media (max-width: 425px) {
      padding-top: 20px !important;

      &:before {
        height: calc(100% - 40px) !important;
      }
    }
  }

  .custom-category-pt {
    padding-top: 40px !important;
  }

  .category-name {
    padding: 1rem;
    margin-bottom: 0;
  }

  .item {
    width: 100%;
    cursor: pointer;

    .row {
      width: 100%;
    }

    .item-name {
      font-weight: bold !important;
    }

    .disabled-item {
      opacity: 0.5;

      .item-price {
        .font-weight-bold {
          font-weight: normal !important;
        }

        @media (max-width: 375px) {
          // flex-direction: column;
          align-items: flex-start;
          column-gap: 8px;

          .fa-circle {
            //display: none;
          }
        }
      }
    }

    .item-description {
      font-weight: 340 !important;
      max-height: 56px;
      margin-bottom: 5px;
      padding-top: 5px;
      overflow: hidden;

      @media (min-width: 1000px) {
        margin-bottom: 10px !important;
      }

      @media (max-width: 450px) {
        margin-bottom: 10px !important;
      }
    }

    .item-description-new {
      font-weight: 340 !important;
      max-height: 56px;
      margin-bottom: 35px;
      padding-top: 5px;
      overflow: hidden;

      @media (min-width: 1000px) {
        margin-bottom: 20px !important;
      }
    }

    .item-price {
      .font-weight-bold {
        font-weight: normal !important;
      }

      font-weight: 550 !important;
      margin-right: 1rem;
      display: block;
      align-items: center;
      column-gap: 8px;
    }

    .item-price-without-description {
      position: absolute;
      bottom: 10px;

      @media (max-width: 768px) {
        bottom: 20px;
      }
    }

    .item-image-rating-container {
      float: right;
      text-align: center;
    }

    .item-image {
      float: right;
      border-radius: 5px;
    }

    &:last-child {
      border-bottom: none;
    }

    &.item-with-quantity {
      border-left: 5px solid var(--primary-color);
    }

    .inline-rating {
      float: right;
      width: 90px;
      text-align: center;
    }

    .rating {
      &.badge {
        position: absolute;
        right: 20px;
      }
    }
  }
}

.font-danger {
  color: var(--card-error-color);
}

.right-swipe {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  height: 100%;
  width: 20%;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #fff;
}

.left-swipe {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  height: 100%;
  width: 20%;
  align-items: center;
  justify-content: center;
  background-color: #ee5e5e;
  color: #fff;
}

.rating-bg {
  .rating.badge {
    font-size: 20px !important;
    padding: 0.7rem !important;
  }
}

.rating-container {
  .rating {
    color: #fff;

    .rating-number {
      margin-left: 4px;
      font-weight: 400;
    }

    &.badge {
      min-width: 50px;
      font-size: 14px;
      border-radius: 3em;
      padding-bottom: 4px;
    }
  }

  .text {
    font-weight: normal;
  }
}

.item-detail {
  .carousel {
    width: 100%;
    height: 450px;

    .slider-wrapper,
    .slide {
      width: 100%;
      height: 450px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }

    .control-dots {
      padding-left: 0;
    }
  }

  .item-name {
    font-weight: 900 !important;

    #hidden-back-button {
      padding-right: 1rem;
    }
  }

  .item-banner {
    margin-bottom: 1.5rem;
  }

  .item-rating {
    width: 100%;
    top: 270px;
    left: 90px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 500px;
    position: absolute;
    z-index: 1031;
    text-align: right;
  }

  .item-intro {
    padding-top: 2.4rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1030;
    background-color: #fff;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .item-price {
      .font-weight-bold {
        font-weight: normal !important;
      }

      padding-top: 0.5rem;
      font-weight: normal !important;
      // flex-direction: row;
    }
  }

  .item-description {
    font-weight: 500 !important;
    padding-left: 16px;
  }

  .item-description-new {
    font-weight: 500 !important;
    padding-left: 16px;
  }

  .placeholder {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;

    .placeholder-image {
      max-width: 100%;
      height: 350px;
    }
  }

  .sticky-top {
    .flex-column-space-between {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.review {
  .review-photo {
    height: 300px;
    display: flex;
    background-size: cover !important;

    .review-info {
      padding: 1em;
      width: 100%;
      align-self: flex-end;

      .review-comment {
        color: #fff;
      }
    }
  }

  &:last-child {
    margin-bottom: 4rem;
  }
}

.back-button {
  position: absolute;
  max-width: $max-width;
  padding: 15px;
  z-index: 1031;
  color: #fff;
  background: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 30%,
    rgba(0, 0, 0, 0.6) 140%
  );
  width: 100%;
  cursor: pointer;
}

.widget-container {
  padding-right: 0 !important;
}

// Separator
.separator {
  min-height: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #eeeeee;
  box-shadow: inset 0 8px 5px -10px #ccc, inset 0 -8px 5px -10px #ccc;
}

.no-shadow {
  min-height: 5px;
  box-shadow: none !important;
}

.quantity-container {
  text-align: center;
  width: 100%;
  justify-content: center;

  .quantity-modifier {
    border: 2px solid var(--primary-color);
    margin: 1rem;
    border-radius: 50px;
    padding: 3px 6px;
  }
}

.item-options {
  .item-option {
    .option-name {
      padding: 1rem;
      background-color: #eeeeee;
    }

    .option-required {
      margin-left: 0.2rem;
      font-weight: normal;
      font-style: italic;
    }

    .option-values {
      padding: 1rem;
      cursor: pointer;
      padding-top: 10px !important;

      .option-value {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        cursor: pointer;

        .option-value-name {
          width: 60%;
        }

        .option-value-quantity {
          color: var(--button-color);
          font-size: 1.4rem;
        }

        .option-value-price {
          width: 100px;
          text-align: right;
          margin-right: 2rem;
          font-weight: normal;
          color: gray;
        }

        .form-check-input {
          transform: scale(1.2);
          position: inherit;
        }
      }
    }
  }

  .select-options {
    position: fixed;
    max-width: $max-width;
    bottom: 0;
    text-align: center;
    color: #fff;
    background-color: var(--primary-color);
    padding: 0.8rem;
    width: 100%;
  }
}

.comments {
  input,
  input:focus {
    outline: none;
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid var(--highlight-color);
  }

  textarea,
  textarea:focus {
    font-size: 1rem;
    resize: none;
  }
}

.view-cart,
.place-order {
  position: fixed;
  //   z-index: 1050;
  max-width: $max-width;
  bottom: 0;
  color: #fff;
  padding: 0.8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  &.enabled {
    background-color: var(--primary-color);
  }

  &.disabled {
    background-color: #ccc;
  }
}

.view-cart {
  .text-decoration-line-through {
    font-weight: 300;
  }
}

.line-item-container {
  .line-item {
    width: 100%;
    display: flex;

    .quantity {
      flex: 0.4;
    }

    .name {
      flex: 3;
    }

    .price {
      flex: 1;
    }
  }

  .actions {
    .add,
    .remove {
      font-size: 2.1rem;
      color: var(--button-color);
      margin-left: 1rem;
    }
  }
}

.line-item-container-custom {
  padding-left: 0 !important;

  .line-item {
    width: 100%;
    display: flex;

    .quantity {
      flex: 0.4;
    }

    .name {
      flex: 3;
    }

    .price {
      flex: 1;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      color: #2e2e2e;
    }

    .line-item-container-item-custom {
      display: flex;
      flex-direction: column;
      width: 100%;

      .name {
        display: flex;

        .name-data {
          width: 70%;
        }

        .price_data {
          font-size: 14px;
          font-weight: normal;
          line-height: 18px;
          color: #2e2e2e;

          display: flex;
          justify-content: flex-end;
        }
      }

      .options-data {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;

        &__left-side {
          .text-muted {
            font-size: 14px;

            font-weight: 300;
            line-height: 18px;

            &:nth-of-type(1) {
              padding-top: 0 !important;
            }
          }
        }

        &__right-side {
          width: 74px;
          margin-right: -1px;

          .actions {
            display: flex;
            width: 100%;
            padding-top: 0 !important;
            background: var(--button-color);
            border-radius: 12px;
            border: 1px solid var(--button-outline-color);

            .remove {
              margin-left: 0;
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              position: relative;
              color: var(--button-font-color);

              img {
                position: absolute;
                top: -2px;
              }
            }

            .add {
              margin-left: 0;
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              cursor: pointer;
              color: var(--button-font-color);

              .svg {
                padding: 5px;
              }

              img {
                position: relative;
                top: -1px;
              }

              &:before {
                content: '';
                width: 1px;
                height: 80%;
                background-color: var(--button-font-color);
                left: 0;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }

  .actions {
    .add,
    .remove {
      font-size: 2.1rem;
      color: var(--button-color);
      margin-left: 1rem;
    }
  }
}

.grand-total,
.gst {
  display: flex;
  justify-content: space-between;
}

.margin-div-bg {
  height: 1px;
  padding-bottom: 4rem;
  background: #f9f9f9;
}

.margin-div {
  height: 1px;
  padding-bottom: 4rem;
}

#dropin {
  margin-bottom: 4rem;
}

.cursor {
  cursor: pointer;
}

.order-status-block {
  color: #fff;
  text-align: center;
  position: sticky;
  top: 55px;
  z-index: 1030;
}

.time-field,
.delivery-fields .location {
  display: flex;
  justify-content: space-between;
}

.time-field {
  .btn-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.min-height-250 {
  min-height: 250px;
}

.new-time-modal {
  .time-component {
    .react-datepicker__header {
      display: none !important;
    }

    .radio-tile-group .input-container .modal-radio-tile {
      border-radius: 5px !important;
    }

    .radio-tile-group .input-container.order-type {
      width: 40.5% !important;
      margin: 10px;
    }

    .radio-tile-group .input-container .radio-tile {
      background: var(--card-color);
      color: var(--card-font-color);
    }
  }
}

.time-modal {
  max-width: 100% !important;
  width: 100%;

  .modal-content {
    border: none;
    border-radius: 0;

    .modal-body {
      padding: 0;
    }

    .modal-header {
      border-radius: 0 !important;
    }
  }

  .time-component {
    .react-datepicker__header {
      display: none !important;
    }

    .date-options {
      margin-bottom: 1rem;
      width: 100%;
      display: flex;

      .option {
        padding: 0.5rem;
        width: 50%;
        text-align: center;
        border: 1px solid #dee2e6;
        font-size: 1.2rem;

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
        }

        &.active {
          font-weight: bold;
          color: var(--primary-color);
        }
      }
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2000 !important;
}

.bottom-fixed-button {
  position: fixed;
  max-width: $max-width;
  bottom: 0;
  color: #fff;
  padding: 0.8rem;
  width: 100%;
  text-align: center;

  &.enabled {
    background-color: var(--primary-color);
  }

  &.disabled {
    background-color: #ccc;
  }
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.font-color-primary {
  color: var(--primary-color) !important;
}

.font-color-danger {
  color: var(--danger) !important;
}

.font-color-white {
  color: #ffffff !important;
}

.font-color-disable {
  color: #ffffff66 !important;
}

.font-color-grey {
  color: #00000066 !important;
}

.border-radius-50 {
  border-radius: 0.2rem;
}

.ReactModalPortal {
  z-index: 100000 !important;
}

.modal {
  display: block !important;
  overflow-y: hidden !important;
}

.modal-dialog {
  max-width: 600px !important;
  margin-bottom: auto !important;
}

.card-custom {
  border-radius: 10px !important;
  border: 1px solid #ced4da;
  background: #ffffff !important;
  padding: 20px 10px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;

  .custom-delivery-title {
    font-size: 16px !important;

    font-weight: 500 !important;
    line-height: 20px !important;
    color: #2e2e2e;
    padding-left: 0 !important;

    svg {
      margin-right: 5px;
    }
  }

  .custom-delivery-location {
    font-size: 14px !important;

    font-weight: 300 !important;
    line-height: 18px !important;
    color: #2e2e2e;
    padding-left: 24px !important;
  }
}
.card_layout_bottom {
  margin-bottom: 1rem !important;
}
.card_layout_top {
  margin-top: 0.25rem !important;
}
@media (max-width: 991px) {
  .card_layout {
    margin: 0px !important;
  }

  .col-md-12 {
    padding: 0px !important;
    padding-left: 10px !important;
  }

  // .col-md-7 {
  //   padding: 0px !important;
  // }
}

@media (max-width: 767px) {
  .col-md-12 {
    padding: 0px !important;
  }

  .header-img {
    padding: 0px !important;
    //padding-bottom: 10px !important;
  }

  .img-thumbnail {
    padding: 0px !important;
  }

  .nav-logo {
    padding: 0px !important;
  }

  .modal-content {
    height: 80% !important;
  }
}

@media (min-width: 767px) {
  .hovernow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease-in-out;
    height: 100%;
  }

  .hovernow:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .SwipeableListItem_swipeableListItem__3xnuO {
    height: 100%;
  }

  .modal-content {
    height: 80% !important;
  }
}

@media (max-width: 500px) {
  .modal-body {
    max-height: 100% !important;
    overflow-y: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .modal-dialog {
    margin: 0px !important;
  }

  .modal-content {
    height: 100% !important;
  }

  .route-pref-modal .modal-content {
    background: transparent !important;
  }

  .route-pref-modal .btn-primary:focus,
  .btn-primary.focus {
    color: #fff;
    background-color: var(--highlight-color) !important;
    border-color: var(--highlight-color) !important;
    box-shadow: 0 0 0 0.2rem var(--highlight-color) !important;
  }

  .item-rating {
    left: 0px !important;
  }

  .time-modal {
    margin: 0;
    position: absolute;
    bottom: 0;
  }

  .cart-column {
    padding: 0px !important;
  }

  .cart-footer {
    position: fixed !important;
  }

  .item-lower-height {
    height: 100px !important;
  }

  .margin-view-cart {
    margin-left: 3rem !important;
  }

  .banner-bar-bg-type {
    font-size: 12px !important;
  }

  .banner-bar-bg-time {
    font-size: 12px !important;
  }

  .banner-bar-bg-outlet {
    font-size: 12px !important;
  }

  .f-12 {
    font-size: 12px !important;
  }
}

.modal-body {
  max-height: calc(100% - 10px);
  overflow-y: auto;
}

.my-map-modal .modal-body {
  height: 80%;
  overflow-y: auto;
}

.hovernow {
  border-bottom: 1px solid rgb(207, 207, 207);
}

.item {
  min-height: 100px;
  height: 100%;
}

.modal-body {
  padding: 0px !important;
}

.SwipeableList_swipeableList__3dl6y {
  background: transparent !important;
}

.close {
  background-color: #007bff;
  right: 4%;
  top: 1.5%;
  position: absolute;
  z-index: 99999;
  background-color: #d8d8d8 !important;
  opacity: 0.7;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
}

.item-price {
  margin-left: auto;
  margin-right: 0;
  // padding:0 !important;
  // background-color: #007bff;
}

.discount-price {
  @media (max-width: 455px) {
    margin: inherit;
  }
}

.actual-price {
  @media (max-width: 455px) {
    margin: inherit;
  }
}

.cart-list {
  position: sticky;
  top: 7%;
  z-index: 1040;

  a {
    button {
      width: 100%;
    }
  }

  .menu-cart-list {
    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}

a {
  text-decoration: none !important;
}

.cart {
  height: 500px;
  overflow-y: scroll;
}

.cart-footer {
  position: sticky;

  @media (min-width: 426px) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.menu-cart-total {
  position: sticky;
  bottom: 0;
  background: white;
}

.menu-cart-list {
  //min-height: 190px;
  //max-height: calc(600px - 200px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.top-desc {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.p-modal {
  padding: 1rem !important;
}

.login-close {
  right: 4%;
  top: 8%;
  position: absolute;
  z-index: 99999;
  background-color: #d8d8d8 !important;
  opacity: 0.7;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding-zero {
  padding: 0px !important;
}

.row-width {
  width: 100%;
}

.navbar-brand {
  margin: 0px !important;
  margin-left: 1rem !important;
}

.btn-outline-primary {
  color: var(--highlight-color) !important;
  border-color: var(--highlight-color) !important;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-primary-msg {
  color: var(--primary-color) !important;
  padding-left: 30px;
}

.btn-primary {
  color: #fff;
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--highlight-color) !important;
  border-color: var(--highlight-color) !important;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: var(--highlight-color) !important;
  border-color: var(--highlight-color) !important;
  box-shadow: 0 0 0 0.2rem var(--highlight-color) !important;
}

.row-bg {
  //background:#f4f5f5;
  background: #f9f9f9;
}

.icon-color {
  color: var(--primary-color) !important;
}

::-webkit-scrollbar {
  width: 5px;
  z-index: 0;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
}

.highlight-color-bg {
  background-color: var(--highlight-color) !important;
  color: var(--highlight-text-color) !important;
}

.highlight-color-text {
  color: var(--highlight-text-color) !important;
}

.h2-color-bg {
  background-color: var(--h2-color) !important;
  color: var(--h2-text-color) !important;
}

.h2-color-text {
  color: var(--h2-text-color) !important;
}

.faded {
  background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75));
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 0.49rem 0.8rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.radio-tile-group .input-container-modal {
  height: 2rem !important;
}

.radio-tile-group .input-container .modal-radio-tile {
  border-radius: 20px !important;
  //   border: 0px !important;
}

.radio-tile-group .input-container .radio-tile-label {
  margin-bottom: 0px !important;
}

.radio-tile-group .input-container.order-type.with-icon {
  width: 23.5%;
}

.input-group-text {
  background-color: white !important;
}

.modal-content-z-index {
  z-index: 10;

  .input-group {
    .input-group-prepend {
      .input-group-text {
        height: 100%;
      }
    }
  }
}

.bg-outlet {
  background: #eeeeee !important;
}

.pref-cell-border {
  border-radius: 10px;
}

.list-group-item {
  border-radius: 10px !important;
  border: 0px !important;
}

.list-group-item.active {
  background: var(--primary-color) !important;
}

.no-border-radius {
  border-radius: 0px !important;
}

button:focus {
  outline: none !important;
}

.current-location-marker {
  background-color: #1e90ff;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  border: solid 4px #fffafa;
}

.marker {
  color: white;
  font-size: 12px;
  background-color: #494949;
  border-radius: 4px;
  font-weight: 600;
  padding: 8px;
  top: -8px;
  width: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.marker.active {
  background-color: var(--primary-color) !important;
}

.marker::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  border: solid 8px transparent;
  border-top-color: #494949;
}

.flex-auto {
  flex: auto !important;
}

.marker.active::after {
  border-top-color: var(--primary-color) !important;
}

.no-border {
  border: 0px !important;
}

.dropdown-menu {
  min-width: 8rem !important;
  background: var(--primary-color) !important;
}

.banner-bar-bg-type {
  background: #e0e0e0;
  color: #4f4f4f;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
}

.banner-bar-bg-time {
  background: #828282;
  color: #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.banner-bar-bg-outlet {
  background: #e0e0e0;
  color: #4f4f4f;
  border-radius: 20px;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

@media (max-width: 500px) {
  .modal.sub-modal-m .top-section .row img {
    width: 35% !important;
  }

  .modal.sub-modal-m .top-section .row div.col-lg-4 {
    text-align: center;
  }

  .modal.sub-modal-m .top-section .row div.col-lg-8 {
    padding-top: 0.5rem !important;
  }
}

.fake-link {
  color: #007bff !important;
  cursor: pointer !important;
}

.fake-link:hover {
  color: #449 !important;
}
.dates-horizontal {
  display: flex;
  flex-wrap: nowrap !important;
  overflow-x: auto;
}
.dates-horizontal::-webkit-scrollbar {
  width: 5px;
  z-index: 0;
  height: 5px;
}
.dates-horizontal-flex {
  flex: 0 0 auto;
  width: 10% !important;
}
.map-search-field {
  width: 90% !important;
  margin-top: 11px !important;
}

.map-search-field {
  width: 90% !important;
  margin-top: 11px !important;
}

.map-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.option-value-space {
  justify-content: space-around !important;
  display: block !important;
}

.option-value-name-space {
  text-align: center !important;
  height: 36px;
  color: #2e3434;
  font-weight: bold;

  @media (max-width: 992px) {
    .add-button {
      width: 100%;
    }
  }
}

.option-icon {
  position: absolute !important;
  right: 10%;
  top: -5%;
}

.option-icon-parent {
  position: relative !important;
  width: 120px;
  height: 120px;
}

.option-value-price-space {
  text-align: center !important;
  font-weight: normal;
  color: gray;
}

.option-value-btn {
  background-color: transparent;
  border: 0px;
}

@media (max-width: 544px) {
  .option-value-name-space {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 544px) {
  .option-value-name-space {
    font-size: 0.9rem !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .option-value-name-space {
    font-size: 0.95rem !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .option-value-name-space {
    font-size: 0.9rem !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .option-value-name-space {
    font-size: 0.9rem !important;
  }
}

/* Header section */
@mixin common-hamburger {
  background: #ffffff;
  border-radius: 6px;
  height: 50px;
  width: 50px;
  border: 1px solid rgba(209, 209, 209, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@mixin common-outlet-information-style {
  cursor: pointer;
  display: flex;
  background: #fff;
  border: 1px solid rgba(209, 209, 209, 0.5);
  border-radius: 40px;
  padding: 14px 30px;

  @media (max-width: 768px) {
    padding: 8px 20px;

    h4 {
      font-size: 12px !important;
    }

    &__order {
      padding-left: 25px !important;
      padding-right: 25px !important;
      margin-left: 20px !important;
    }

    &__serving-time {
      padding-left: 25px !important;
    }
  }

  @media (max-width: 920px) {
    padding: 8px 20px;

    h4 {
      font-size: 12px !important;
    }

    &__order {
      padding-left: 25px !important;
      padding-right: 25px !important;
      margin-left: 20px !important;
    }

    &__serving-time {
      padding-left: 25px !important;
    }
  }

  h3,
  h4 {
    margin: 0;
  }

  h3 {
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    color: #6e6e6e;
    height: fit-content;
    padding-bottom: 4px;
  }

  h4 {
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    color: var(--primary-color);
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  &__outlet {
  }

  &__order {
    cursor: pointer;
    padding-left: 30px;
    border-left: 0.5px solid var(--card-subtitle-color);
    padding-right: 30px;
    margin-left: 30px;
  }

  &__serving-time {
    padding-left: 30px;
    border-left: 0.5px solid var(--card-subtitle-color);
  }
}

.header-banner {
  position: relative;

  .swiper {
    .swiper-wrapper {
      .swiper-slide {
        width: 100% !important;
        position: relative;
        padding-top: calc(500 / 1280 * 100%);
        //padding-top: calc(500 / 1280 * 80%);
        object-fit: cover;

        img {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          height: 100%;
          object-fit: cover;
        }

        //&:before{
        //  content: "";
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  width: 100%;
        //  height: 100%;
        //  background: rgba(10,10,10,0.6);
        //  z-index: 1;
        //}
      }
    }

    .swiper-pagination {
      bottom: 14px;

      .swiper-pagination-bullet,
      .swiper-pagination-bullet-active {
        width: 30px;
        border-radius: 5px;
        background: #ffffff;
        height: 3px;
      }

      .swiper-pagination-bullet-active {
        opacity: 0.6;
      }
    }
  }
}

.banner-top-section-with-bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.banner-top-section-without-bg-image {
  background: #fff;
  position: relative !important;

  .menu {
    &__link {
      h3 {
        color: #000000 !important;
      }
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .go-back-section {
      .title {
        font-size: 14px !important;
      }
    }

    .menu {
      column-gap: 10px !important;
    }

    .outlet-information {
      padding: 8px 15px !important;
    }
  }

  @media (max-width: 768px) {
    .outlet-information {
      display: none !important;
    }

    .go-back-section {
      column-gap: 10px !important;

      .back-img {
        width: 40px !important;
        height: 40px !important;
      }

      .title {
        font-size: 16px !important;
      }
    }
  }
}

.banner-top-section {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 120px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 55px;

  //background: #fff;
  @media (max-width: 768px) {
    padding: 0 24px;
    height: 80px;

    .logo {
      width: 60px !important;
      height: 60px !important;

      // img {
      //   width: 48px;
      //   height: 43px;
      // }
    }
  }

  @media (max-width: 425px) {
    padding: 0 20px;
  }

  .logo {
    //width: 100px;
    //height: 100px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border-radius: 50%;
      height: 70px;
      width: 70px;
    }
  }

  .order-pref-width {
    background: #ffffff;
    border: #d1d1d1;
    border-radius: 40px;
    width: 100%;
  }

  .outlet-information {
    label {
    }

    @include common-outlet-information-style;

    @media (min-width: 427px) and (max-width: 630px) {
      padding: 4px 8px;

      &__order {
        padding-left: 4px !important;
        padding-right: 4px !important;
        margin-left: 4px !important;

        h3 {
          line-height: 12px;
        }
      }

      &__serving-time {
        padding-left: 4px !important;

        h3 {
          line-height: 12px;
        }
      }

      &__outlet {
        h3 {
          line-height: 12px;
        }
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 30px;

    @media (min-width: 500px) and (max-width: 630px) {
      column-gap: 2px;
    }

    &__link {
      cursor: pointer;

      h3 {
        font-size: 16px;
        font-weight: normal;

        line-height: 20px;
        margin: 0;
        color: #ffffff;
        text-decoration: underline;
      }
    }

    &__hamburger {
      @include common-hamburger;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .go-back-section {
    display: flex;
    column-gap: 20px;
    align-items: center;

    .back-img {
      width: 50px;
      height: 50px;
      border: 1px solid rgba(209, 209, 209, 0.5);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .title {
      font-size: 20px;

      font-weight: 900;
      color: #2e2e2e;
      margin: 0;
      line-height: 23px;
    }
  }
}

.hamburger-menu-data {
  ul {
    position: fixed;
    //position: absolute;
    width: 325px;
    background: #ffffff;
    box-shadow: -3px 20px 20px rgba(0, 0, 0, 0.06);
    height: 100vh;
    top: 0;
    right: -100%;
    padding: 35px 55px 0 0;
    list-style-type: none;
    transition: all 0.35s ease-in-out;
    z-index: 1250;

    @media (max-width: 768px) {
      padding: 20px 14px 0 0;
    }

    @media (max-width: 425px) {
      padding-right: 0;

      li {
        padding-right: 20px !important;
      }
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 15px;
      justify-content: flex-end;
      padding: 20px 0;
      // border-bottom: 1px solid #e9e9e9;
      cursor: pointer;

      &:nth-of-type(1) {
        cursor: unset;
        padding-top: 0;
        border-bottom: unset;
        padding-bottom: 38px;
      }

      h3 {
        margin: 0;
        font-size: 16px;

        font-weight: bold;
        line-height: 14px;
        color: var(--primary-font-color);
        transition: 0.5s;
      }

      &:hover {
        h3 {
          color: var(--primary-font-color);
        }
      }
    }

    .hamburger-close {
      &__close {
        @include common-hamburger;

        img {
          @media (max-width: 768px) {
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }

    @media (max-width: 425px) {
      //width: 100%;
      padding: 20px 14px 0 0;
    }
  }

  .sidebar-menu-notification-data {
    right: -100%;
    padding-left: 25px;
    padding-right: 0;
    transition: all 0.5s ease-in-out;
    overflow: auto;

    li {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-bottom: 1px solid #e9e9e9 !important;
      padding-bottom: 15px !important;
      row-gap: 10px;
      cursor: unset;

      &:hover {
        h3 {
          color: #fff;
        }
      }

      h3,
      h4,
      h5 {
        margin: 0;
      }

      h3 {
        background: var(--primary-color);
        padding: 2px 4px;
        border-radius: 4px;
        color: #ffffff;

        font-size: 12px;
        font-weight: 300;
        line-height: 18px;

        &:hover {
          color: #ffffff;
        }
      }

      h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: #2e2e2e;
        padding-right: 15px;
      }

      h5 {
        color: #6e6e6e;

        font-size: 12px;
        line-height: 18px;
        font-weight: normal;
      }
    }

    .hamburger-close {
      border-bottom: unset !important;
      align-items: flex-end !important;
      padding-right: 55px;
      cursor: pointer;

      @media (max-width: 768px) {
        padding-right: 24px !important;

        &__close {
          img {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }

      @media (max-width: 425px) {
        padding-right: 20px !important;
      }
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }
}

.desktop-outlet {
  @media (max-width: 499px) {
    display: none !important;
  }
}

.mobile-outlet {
  @media (min-width: 500px) {
    display: none;
  }

  @include common-outlet-information-style;
  border-radius: unset;
  border: unset;
  padding: 24px 20px !important;

  .outlet-information-mobile {
  }

  .serving-time-mobile {
  }

  .order-mobile {
    padding: 0 20px;
    margin: 0 20px;
    border-right: 1px solid var(--card-subtitle-color);
    border-left: 1px solid var(--card-subtitle-color);
  }

  @media (max-width: 499px) {
    display: flex !important;

    .outlet-information-mobile,
    .order-mobile,
    .serving-time-mobile {
      width: 33%;
      flex: 0 0 33%;
      max-width: 33%;
    }

    .outlet-information-mobile {
      svg {
        min-width: 14px !important;
        min-height: 14px !important;
      }

      h4 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 90%;
        column-gap: 5px !important;
        font-weight: 600 !important;
      }
    }

    .order-mobile {
      margin: 0;

      h4 {
        column-gap: 5px !important;
        font-weight: 600 !important;
      }
    }

    .serving-time-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h4 {
        column-gap: 5px !important;
        font-weight: 600 !important;
      }
    }
  }
}

//Restaurent Setting
.restaurant-icon {
  width: 16px !important;
  height: 16px !important;
  position: unset !important;

  @media (max-width: 425px) {
    width: 14px !important;
    height: 14px !important;
  }
}

//old info
.info-old {
  .address,
  .telephone {
    a {
      display: flex;
      column-gap: 5px;
      align-items: center;
    }
  }

  .opening-hours,
  .minimum-order {
    display: flex;
    column-gap: 5px;
    align-items: center;
  }

  @media (max-width: 426px) {
    padding: 0 15px;
  }
}

.no-banner-restaurant-settings-header-img {
  position: relative;
  padding: 0;
  // height: 208px;
  display: flex;
  align-items: center;

  .header-img {
    padding-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }

  .info-custom {
    //display: flex;
    //align-items: center;
    //display: grid;
    //align-items: center;
    padding-left: 0 !important;

    @media (max-width: 768px) {
      .col-md-3 {
        flex: 0 0 33% !important;
        width: 33% !important;
      }
    }

    @media (max-width: 767px) {
      .row {
        flex-direction: column;
        row-gap: 15px;

        .telephone {
          width: 100%;
          border-right: unset;
          border-left: unset;
          margin-left: unset;
          padding-left: 15px;
        }

        .opening-hours {
          padding-left: 15px;
          border: unset;

          @media (max-width: 426px) {
            .ps-2 {
              font-size: 14px;
            }
          }
        }
      }
    }

    .address {
      margin-top: 0 !important;
      display: flex;
      align-items: center;
      padding-right: 0 !important;

      .restaurant-icon {
        margin-top: 2px !important;
      }

      a {
        display: flex;
      }

      @media (min-width: 1500px) {
        max-width: fit-content;
      }

      @media (max-width: 768px) {
        flex: 0 0 45% !important;
        width: 45%;
        max-width: 45%;
      }

      @media (max-width: 767px) {
        flex: 0 0 100% !important;
        width: 100%;
        max-width: 100%;
      }

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 20px;
        padding-left: 8px;
        color: #e9e9e9;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .telephone {
      display: flex;
      align-items: center;
      max-width: fit-content;
      padding: 0 40px;
      margin-top: 0 !important;
      border-right: 1px solid #f9f9f9;
      border-left: 1px solid #f9f9f9;
      margin-left: 70px;

      @media (max-width: 768px) {
        margin-left: 36px;
      }

      @media (max-width: 767px) {
        .ps-2 {
          font-size: 14px !important;
        }
      }

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 16px;
        padding-left: 10px;
        color: #e9e9e9;
      }

      .restaurant-icon {
        margin-bottom: 4px;
      }
    }

    .opening-hour-without-telephone {
      border-left: 1px solid #f9f9f9;

      @media (max-width: 768px) {
        border-left: unset;
      }
    }

    .opening-hours {
      display: flex;
      align-items: center;
      border-right: 1px solid #f9f9f9;
      max-width: fit-content;
      padding: 0 40px;
      margin-top: 0 !important;

      @media (max-width: 768px) {
        border-right: unset;
      }

      @media (max-width: 425px) {
        flex: 0 0 100% !important;
        width: 100% !important;
      }

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 16px;
        padding-left: 10px;
        color: #e9e9e9;
      }
    }

    .minimum-order {
      //display: flex !important;
      align-items: center;
      max-width: fit-content;
      padding-left: 40px;
      margin-top: 0 !important;
      padding-right: 0 !important;

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 16px;
        padding-left: 10px;
        color: #e9e9e9;
      }
    }

    @media (min-width: 1620px) {
      .telephone {
        padding: 0 80px;
        margin-left: 120px;
      }

      .opening-hours {
        padding: 0 80px;
      }

      .minimum-order-desktop {
        padding-left: 80px;
      }
    }

    @media (min-width: 1024px) and (max-width: 1099px) {
      .telephone {
        padding: 0 30px;
        margin-left: 40px;
      }

      .opening-hours {
        padding: 0 30px;
      }

      .minimum-order-desktop {
        padding-left: 30px;
      }
    }

    @media (min-width: 769px) and (max-width: 799px) {
      .address {
        .ps-2 {
          height: 40px;
          overflow: hidden;
          font-size: 14px;
        }
      }

      .telephone {
        padding: 0 16px;
        margin-left: 16px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .opening-hours {
        padding: 0 16px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .minimum-order-desktop {
        padding-left: 16px;

        .ps-2 {
          font-size: 14px;
        }
      }
    }

    @media (min-width: 800px) and (max-width: 1023px) {
      .address {
        .ps-2 {
          height: 40px;
          overflow: hidden;
          font-size: 14px;
        }
      }

      .telephone {
        padding: 0 24px;
        margin-left: 24px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .opening-hours {
        padding: 0 16px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .minimum-order-desktop {
        padding-left: 16px;

        .ps-2 {
          font-size: 14px;
        }
      }
    }
  }

  .intro {
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding-left: 0 !important;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      padding-top: 0 !important;
    }

    @media (max-width: 768px) {
      h3 {
        font-size: 24px !important;
        line-height: 28px !important;
      }
    }

    h3 {
      font-size: 30px;
      font-weight: 600;

      line-height: 35px;
      color: #ffffff;
      margin: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      color: #e9e9e9;

      margin: 0;
    }

    .circle-dot {
      background: #e9e9e9;
      height: 4px;
      width: 4px;
      border-radius: 50%;
    }
  }

  .header-img {
    padding: 55px 55px !important;

    @media (max-width: 768px) {
      padding: 20px 25px !important;
    }

    @media (max-width: 426px) {
      padding: 25px 20px !important;
    }
  }

  .minimum-order-mobile {
    display: none !important;

    @media (max-width: 768px) {
      display: flex !important;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-top: 0 !important;
      padding-top: 25px;
      padding-left: 15px !important;

      .minimum-order {
        padding-left: unset;
      }
    }

    @media (max-width: 767px) {
      width: 100% !important;
      flex: 0 0 100% !important;
      padding-top: 0 !important;

      .minimum-order {
        padding-left: unset;
        width: 100%;

        .ps-2 {
          font-size: 14px;
        }
      }
    }
  }

  .minimum-order-desktop {
    display: flex !important;

    @media (max-width: 768px) {
      display: none !important;
    }
  }
}

.restaurant-settings {
  position: relative;
  padding: 0;

  .row-without-telephone {
    @media (min-width: 769px) {
      .col-md-4,
      .col-md-3 {
        width: 33% !important;
        max-width: 33% !important;
        flex: 0 0 33% !important;
      }
    }
  }

  .header-img {
    padding-bottom: 0;

    @media (max-width: 768px) {
      padding: 30px 24px !important;
    }

    .col-md-12 {
      z-index: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    opacity: 0.8;
  }

  .info-custom {
    //display: flex;
    //align-items: center;
    //display: grid;
    //align-items: center;
    //padding-left: 0 !important;
    .row {
      margin-right: 0 !important;
    }

    @media (min-width: 769px) {
      .col-md-4,
      .col-md-3 {
        flex: 0 0 25%;
        max-width: 25% !important;
      }
    }

    @media (max-width: 768px) {
      margin-right: -8px !important;

      .row {
        .col-md-3 {
          padding-top: 0;
          align-items: center;
          flex: 0 0 33%;
          width: 33%;
          padding-right: 0;
          max-width: 33%;
        }

        .telephone {
          margin-left: 0 !important;
          justify-content: center;
          padding: 0;
        }

        .minimum-order-mobile {
          justify-content: flex-end;
        }
      }
    }

    @media (max-width: 767px) {
      margin-right: 0 !important;

      .col-md-3 {
        justify-content: flex-start !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
        width: 100% !important;
      }

      .row {
        flex-direction: column;
        row-gap: 15px;

        .telephone {
          width: 100%;
          border-right: unset;
          border-left: unset;
          margin-left: unset;
          //padding-left: 15px !important;
        }

        .opening-hours {
          //padding-left: 15px;
          border: unset;

          @media (max-width: 426px) {
            .ps-2 {
              font-size: 14px;
            }
          }
        }
      }
    }

    @media (max-width: 425px) {
      .col-md-3 {
        //padding: 0 15px !important;
        flex: 0 0 100% !important;
        width: 100% !important;
      }
    }

    .address {
      margin-top: 0 !important;
      display: flex;
      align-items: center;
      padding-right: 0 !important;
      //padding-left: 0 !important;

      a {
        display: flex;
      }

      @media (min-width: 1500px) {
        max-width: fit-content;
      }

      @media (max-width: 768px) {
        flex: 0 0 33% !important;
        width: 33%;
        max-width: 33%;
        padding-right: 24px !important;
      }

      @media (max-width: 767px) {
        flex: 0 0 100% !important;
        width: 100%;
        max-width: 100%;
      }

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 20px;
        padding-left: 8px;
        color: #e9e9e9;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 18px;
          padding-left: 0.8rem !important;
        }
      }
    }

    .telephone {
      display: flex;
      align-items: center;
      max-width: fit-content;
      //padding: 0 40px;
      margin-top: 0 !important;
      border-right: 1px solid var(--header-font1-color);
      border-left: 1px solid var(--header-font1-color);
      //margin-left: 70px;
      justify-content: center;

      @media (max-width: 768px) {
        margin-left: 20px;

        .ps-2 {
          font-size: 14px !important;
        }

        .restaurant-icon {
          margin-bottom: 6px !important;
        }
      }

      @media (max-width: 767px) {
        padding-left: 15px !important;

        .ps-2 {
          font-size: 14px !important;
        }
      }

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 16px;
        padding-left: 10px !important;
        color: #e9e9e9;
      }
    }

    .opening-hour-without-telephone {
      border-left: 1px solid #f9f9f9;

      @media (max-width: 767px) {
        border-left: unset;
      }
    }

    .opening-hours {
      display: flex;
      align-items: center;
      border-right: 1px solid var(--header-font1-color);
      max-width: fit-content;
      //padding: 0 40px;
      margin-top: 0 !important;
      justify-content: center;

      @media (max-width: 768px) {
        padding: 0 15px !important;
        border-right: unset;
        flex: 0 0 100%;
        width: 100%;
        display: none;

        .ps-2 {
          font-size: 14px !important;
        }
      }

      @media (max-width: 767px) {
        display: flex;
        padding-left: 15px !important;
      }

      @media (max-width: 425px) {
        //padding: 0 15px !important;
        flex: 0 0 100%;
        width: 100%;
      }

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 16px;
        padding-left: 10px !important;
        color: #e9e9e9;
      }
    }

    .minimum-order {
      //display: flex !important;
      align-items: center;
      max-width: fit-content;
      //padding-left: 40px;
      margin-top: 0 !important;
      padding-right: 0 !important;
      justify-content: center;

      .icon-color {
        color: #ffffff !important;
      }

      .ps-2 {
        font-size: 16px;

        font-weight: 300;
        line-height: 16px;
        padding-left: 10px !important;
        color: #e9e9e9;
      }

      @media (max-width: 768px) {
        .ps-2 {
          font-size: 14px !important;
        }

        .restaurant-icon {
          margin-bottom: 3px !important;
        }
      }

      @media (max-width: 767px) {
        padding-left: 15px !important;
      }
    }

    @media (min-width: 1620px) {
      .telephone {
        //padding: 0 80px;
        //margin-left: 120px;
      }

      .opening-hours {
        //padding: 0 80px;
      }

      .minimum-order-desktop {
        //padding-left: 80px;
      }
    }

    @media (min-width: 1024px) and (max-width: 1099px) {
      //.telephone {
      //  padding: 0 30px;
      //  margin-left: 40px;
      //}
      //.opening-hours {
      //  padding: 0 30px;
      //}
      //.minimum-order-desktop {
      //  padding-left: 30px;
      //}
    }

    @media (min-width: 769px) and (max-width: 799px) {
      .address {
        .ps-2 {
          height: 40px;
          overflow: hidden;
          font-size: 14px;
        }
      }

      .telephone {
        //padding: 0 16px;
        //margin-left: 16px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .opening-hours {
        //padding: 0 16px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .minimum-order-desktop {
        //padding-left: 16px;

        .ps-2 {
          font-size: 14px;
        }
      }
    }

    @media (min-width: 800px) and (max-width: 1023px) {
      .address {
        .ps-2 {
          height: 40px;
          overflow: hidden;
          font-size: 14px;
        }
      }

      .telephone {
        //padding: 0 24px;
        //margin-left: 24px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .opening-hours {
        //padding: 0 16px;

        .ps-2 {
          font-size: 14px;
        }
      }

      .minimum-order-desktop {
        //padding-left: 16px;

        .ps-2 {
          font-size: 14px;
        }
      }
    }
  }

  .intro-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .opening-closing-time {
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;

      margin: 0;
      color: #e9e9e9;
      column-gap: 12px;
      display: none;

      @media (max-width: 768px) {
        display: flex;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .intro {
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding-left: 0 !important;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
      h4 {
        margin-top: 10px !important;
      }
    }

    @media (max-width: 768px) {
      padding-top: 0 !important;
    }

    @media (max-width: 768px) {
      h3 {
        font-size: 20px !important;
        line-height: 28px !important;
      }
    }

    h3 {
      font-size: 30px;
      font-weight: 700;

      line-height: 35px;
      color: #ffffff;
      margin: 0;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      color: #e9e9e9;

      margin-bottom: 0;
      margin-top: 7px;
    }

    .circle-dot {
      background: #e9e9e9;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      margin-top: 10px;
    }
  }

  .header-img {
    padding: 40px 55px !important;

    @media (max-width: 768px) {
      padding: 30px 24px !important;
    }

    @media (max-width: 426px) {
      padding: 25px 20px !important;
    }
  }

  .minimum-order-mobile {
    display: none !important;

    @media (max-width: 768px) {
      display: flex !important;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-top: 0 !important;
      padding-top: 25px;
      padding-left: 15px !important;

      .minimum-order {
        padding-left: unset;
      }
    }

    @media (max-width: 767px) {
      width: 100% !important;
      flex: 0 0 100% !important;
      padding-top: 0 !important;
      padding-left: 0 !important;

      .minimum-order {
        padding-left: unset;
        width: 100%;

        .ps-2 {
          font-size: 14px;
        }
      }
    }
  }

  .minimum-order-desktop {
    display: flex !important;

    @media (max-width: 768px) {
      display: none !important;
    }
  }
}

//Category section
.cart-list.cart-list-pinned {
  height: 80vh;
}

@mixin common-menu-cart-list-style {
  padding: 0 20px;

  .line-item-container-custom {
    padding-bottom: 30px;
    padding-right: 0 !important;
    border: unset !important;

    &:nth-of-type(1) {
      //border-top: 0.5px solid #E9E9E9 !important;
      padding-top: 15px !important;
    }

    .line-item {
      .quantity {
        font-size: 12px;
        font-weight: normal;

        line-height: 15px;
        width: 22px;
        height: 20px;
        background: var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        color: #ffffff;
        margin-right: 10px;
      }

      .name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

        .text-muted {
          padding-top: 5px;
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          color: #6e6e6e;
        }
      }

      .price {
        display: flex;
        flex-direction: column;
        column-gap: 5px;
        font-size: 14px;
        font-weight: normal !important;

        line-height: 18px;
        align-items: flex-end;
      }
    }
  }

  .line-item-tax {
    padding-top: 15px;
    border-top: 0.5px solid var(--highlight-color) !important;
  }

  .line-item-discount {
    border-top: unset !important;
    padding-top: 0 !important;
  }
}

.category-section-row {
  .cart-list-col {
    margin-top: 0 !important;
    padding-right: 55px !important;

    @media (max-width: 768px) {
      padding-right: calc(55px - 24px - 10px) !important;
      padding-left: 22px;
      flex: 0 0 47.66667%;
      max-width: 47.66667%;
    }

    .order-status-section {
      position: sticky;
      top: 100px;
    }

    .cart-list-with-status {
      top: 158px !important;

      @media (max-width: 768px) {
        top: 148px !important;
      }
    }

    .cart-list {
      position: sticky;
      //top: 7%;
      //top: 12%;
      //top: 162px;
      top: 100px;
      z-index: 1040;
      border: unset;
      box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.06);
      border-radius: 10px !important;
      //padding: 15px 20px !important;
      padding: 0 !important;
      flex-direction: column;

      //flex-direction: column-reverse;
      @media (max-width: 768px) {
        top: 90px;
      }

      .my-order {
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;

        color: #2e2e2e;
        margin: 0;
        padding: 15px 20px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 20px;
          width: calc(100% - 40px);
          height: 1px;
          background: var(--highlight-color);
        }
      }

      .cart-checkout-disabled {
        display: flex;
        flex-direction: column-reverse;

        a {
          margin-top: 15px;
          padding: 15px 20px;
          box-shadow: 0px -3px 12px 0px rgba(0, 0, 0, 0.06);

          .highlight-color-bg {
            outline: unset;
            padding: 12px 52px;
            color: #ffffff;

            font-weight: 600;
            line-height: 20px;
            border-radius: 5px;
            width: 100%;

            &:focus {
              border: unset !important;
            }

            &:active {
              border: unset !important;
            }
          }
        }

        .text-center {
          padding: 25px 20px !important;
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
        }
      }

      .cart-checkout-section {
        margin-top: 15px;
        padding: 15px 20px;
        box-shadow: 0px -3px 12px 0px rgba(0, 0, 0, 0.06);
        //position: sticky;
        //bottom: 0;

        &__total {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;

          .name {
            color: #2e2e2e;
            margin: 0;

            font-weight: 500 !important;
            line-height: 20px;
            font-size: 18px;
          }

          .price {
            font-size: 18px;
            font-weight: 500 !important;
            line-height: 20px;
            color: #2e2e2e;
            margin: 0;
          }
        }

        a {
          border-radius: 5px;

          button {
            padding: 12px 52px;
            color: var(--highlight-text-color);

            font-weight: 600;
            line-height: 20px;
            width: 100%;
          }
        }
      }

      .menu-cart-list {
        @include common-menu-cart-list-style;
        max-height: 400px;
        min-height: 190px;
      }
    }
  }

  .category-items-col {
    padding-left: 55px !important;

    @media (max-width: 768px) {
      max-width: 400px;
      //padding-left: calc(55px - 24px - 7px) !important;
      padding-left: 0px !important;
    }

    @media (max-width: 767px) {
      max-width: 100% !important;
    }

    @media (min-width: 426px) and (max-width: 767px) {
      //padding-right: calc(55px - 24px - 15px) !important;
      padding-right: 0px !important;
    }

    @media (max-width: 426px) {
      padding-left: unset !important;
    }
  }

  .category-section {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 1041;
    padding-right: 109px;
    padding-left: 35px;

    //margin-bottom: 30px;
    .category-section-first {
      display: flex;
      width: 100%;
      margin: 0 auto;
      align-items: flex-start;
      column-gap: 5px;
      padding-top: 20px !important;
      //padding: 0 110px 0 55px;
      //padding: 20px 55px 30px 55px;

      //margin: 10px 0 0px 0;
      background-color: transparent;

      //column-gap: 20px;
      //column-gap: 20px;
      @media (min-width: 769px) {
        height: 80px;
        padding-left: 20px;
        z-index: 999 !important;
        // margin-top: 5px;
      }

      @media (max-width: 768px) {
        height: 90px;
        padding: 0 68px 0 35px;
        z-index: 999 !important;
      }

      @media (max-width: 767px) {
        height: 50px;
        padding: 0 72px 0 24px !important;
      }

      //@media(max-width: 768px) {
      //  padding: 20px 24px;
      //}
      @media (max-width: 426px) {
        margin-bottom: 0;
        padding: 0 47px 0 0px !important;
      }

      //@media(max-width: 425px){
      //  height: 90px;
      //  padding-top: 20px !important;
      //}

      &__searchbar {
        //display: flex;
        align-items: center;
        //display: none;
        //width: 100%;
        width: 50px;
        //box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
        border-radius: 6px;

        @media (max-width: 768px) {
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
        }

        @media (min-width: 426px) {
          width: 50px;
        }

        @media (max-width: 425px) {
          //width: unset;
          display: flex;

          &__icon {
            border-top-left-radius: unset !important;
            border-bottom-left-radius: unset !important;
            width: 50px !important;
            height: 50px !important;
          }
        }

        &__icon {
          width: 50px;
          height: 50px;
          border-radius: 6px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          cursor: pointer;
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);

          @media (max-width: 768px) {
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }

          @media (max-width: 425px) {
            img {
              width: 25px;
              height: 25px;
            }
          }
        }

        &__input-group {
          display: none;
          width: calc(100% - 50px);
          height: 50px;
          //display: flex;
          transition: 0.5s;

          @media (max-width: 425px) {
            width: calc(100% - 40px);
            height: 40px;

            &__close-icon {
              width: 40px !important;
              height: 40px !important;
            }
          }

          input {
            margin: 0;
            width: calc(100% - 20px);
            padding: 16px 52px 16px 25px;
            outline: unset;
            border: unset;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;

            &::placeholder {
              color: #d1d1d1;

              font-weight: 300;
              font-size: 16px;
              line-height: 18px;
            }
          }

          &__close-icon {
            width: 50px;
            height: 50px;
            background: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            cursor: pointer;
          }
        }
      }

      .category-list {
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
        //display: none;
        height: 50px;
        padding-right: 56px !important;
        padding-left: 10px !important;
        //padding: 0 55px;
        transition: 0.5s;
        align-items: center;
        overflow-y: hidden;

        @media (max-width: 768px) {
          //padding: 0 24px;
        }

        @media (max-width: 499px) {
          //padding: 0 24px;
        }

        @media (max-width: 430px) {
          //height: 40px;
          border-top-right-radius: unset !important;
          border-bottom-right-radius: unset !important;
          width: 100%;
        }

        .category {
          margin: 0;
          padding: 0.25rem 0.5rem;
          border-radius: 25px !important;
          display: flex;
          justify-content: center;
          align-items: center;

          font-weight: 700;
          font-size: 0.9rem;
          line-height: 20px;
          //max-height: 36px;
          height: 32px;
          margin: 5px;

          @media (max-width: 425px) {
            padding: 4px 8px;
            height: 30px;
          }
        }

        .active {
          background-color: var(--highlight-color) !important;
          color: var(--highlight-text-color) !important;
        }
      }
    }

    .category-section-second {
      z-index: 2;
    }

    @media (max-width: 768px) {
      padding-right: 0 !important;
      padding-left: 0 !important;

      .category-section-first {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }

  .searchbar_open {
    display: flex !important;
    animation-name: opensidebar;
    animation-duration: 1s;

    @keyframes opensidebar {
      0% {
        height: 0px;
      }

      25% {
      }

      50% {
      }

      100% {
        height: 50px;
      }
    }
  }

  .searchbar_close {
    animation-name: closesidebar;
    animation-duration: 2s;

    @keyframes closesidebar {
      0% {
        height: 50px;
      }

      25% {
        height: 25px;
      }

      50% {
        height: 0px;
      }

      100% {
        display: none !important;
      }
    }
  }

  .searchbar {
    //display: flex;
    position: absolute;
    align-items: center;
    display: none;
    width: 100%;
    margin-bottom: 20px;
    //width: 50px;
    height: 0;
    transition: 0.5s;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    left: 54px;

    //@media(min-width: 426px){
    //  width: 50px;
    //}
    @media (max-width: 768px) {
      position: absolute;
      //top: 90px;
      z-index: 1030;
      left: 26px;
      width: 100% !important;
      box-shadow: unset !important;
    }

    @media (max-width: 767px) {
      //top: 50px;
      left: 0;
      //border-radius: unset !important;
    }

    @media (max-width: 426px) {
      //margin-top: 10px;
      //padding: unset !important;
      //@include: unset !important;
      //top: 40px;
      z-index: 1020;
      //border-radius: unset !important;
      width: 100% !important;
      box-shadow: unset !important;

      &__icon {
        width: 40px !important;
        height: 40px !important;
      }
    }

    &__icon {
      display: none !important; // for ver 1.3.2
      width: 50px;
      height: 50px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background: #ffffff;
      //display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
    }

    &__input-group {
      display: flex;
      //width: calc(100% - 50px);
      width: 100%;
      height: 50px;
      //display: flex;
      transition: 0.5s;

      @media (max-width: 425px) {
        //width: calc(100% - 40px);
        height: 50px;

        &__close-icon {
          width: 40px !important;
          height: 40px !important;
          border-radius: unset !important;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }

      input {
        margin: 0;
        width: 100%;
        padding: 16px 52px 16px 25px;
        outline: unset;
        border: unset;
        //border-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;

        &::placeholder {
          color: #d1d1d1;

          font-weight: 300;
          font-size: 16px;
          line-height: 18px;
        }

        @media (max-width: 767px) {
          //border-radius: unset !important;
        }

        @media (max-width: 425px) {
          border-radius: unset !important;
        }
      }

      &__close-icon {
        width: 50px;
        height: 50px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: pointer;
        margin-left: -1px;

        @media (max-width: 767px) {
          border-radius: unset !important;
        }

        @media (max-width: 425px) {
          height: 50px !important;
        }
      }
    }

    //&:before{
    //  position: absolute;
    //  content: "";
    //  top: -21px;
    //  width: 100%;
    //  height: 25px;
    //  background: #F9F9F9;
    //  z-index: -1;
    //}
  }

  .searchbar-without-promotion {
    margin-bottom: 0 !important;
  }

  .searchbar-mobile {
    display: none;

    @media (max-width: 425px) {
      display: flex;
      background: #fff;
      padding: 0 11px;
      position: sticky;
      top: 50px;
      z-index: 1020;
      margin-bottom: 0;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
      border-radius: unset !important;
      height: 50px;

      .searchbar__icon {
        border-radius: unset !important;
        box-shadow: unset !important;
      }
    }
  }
}

.hide-category-list {
  display: none;
}

.show-search-input-field {
  display: flex;
}

.horizontal-scroll {
  min-width: 100%;

  @media (max-width: 768px) {
    min-width: calc(100% - 77px) !important;
  }

  @media (max-width: 425px) {
    min-width: calc(100% - 71px) !important;
  }
}

// Modal title
.login-modal-title {
  .modal-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
    }

    h2 {
      font-size: 32px;

      font-weight: 300;
    }
  }
}

// Order status section
.order-status-section {
  @media (max-width: 768px) {
    top: 91px !important;
  }

  .order-status-block {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 14px;
    //background-color: var(--primary-color) !important;
    background-color: #ff5b5b !important;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
  }
}

// Promotional items
.promotional-items {
  display: flex;
  column-gap: 25px;
  border-bottom: 1px solid rgba(233, 233, 233, 0.5);

  //padding-bottom: 30px;
  @media (max-width: 768px) {
    //padding-bottom: 15px;
    position: relative;
    border-bottom: unset;

    &:before {
      content: '';
      position: absolute;
      width: 100% !important;
      height: 1px;
      //background: #e9e9e9;
      bottom: 0;
      margin: 0 auto;
      //left: 20px !important;
    }
  }

  @media (min-width: 426px) {
    //padding-bottom: 15px;
    position: relative;
    border-bottom: unset;
    //margin-top: 10px;

    &:before {
      content: '';
      position: absolute;
      width: 100% !important;
      height: 1px;
      //background: #e9e9e9;
      bottom: 0;
      margin: 0 auto;
      //left: 20px !important;
    }
  }

  @media (max-width: 767px) {
    //margin-top: 20px;
  }

  @media (max-width: 426px) {
    position: relative;
    border-bottom: unset;

    //padding-top: 20px;
    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 40px) !important;
      height: 1px;
      //background: #e9e9e9;
      bottom: 0;
      margin: 0 auto;
      left: 20px !important;
    }
  }

  &__card {
    position: relative;
    padding-top: calc(120 / 240 * 100%);
    //width: 100% !important;
  }

  &__cardloyalty {
    position: relative;
    padding-top: calc(120 / 240 * 100%);
    //width: 100% !important;
  }

  &__bincard {
    // position: relative;
    // padding-top: calc(10 / 240 * 100%);
    // padding-bottom: calc(10 / 240 * 100%);

    background: #e0e0e0;
    //width: 100% !important;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  .binimg {
    border-radius: 0px !important;
    position: relative !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  &__bincontents {
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); */
    background: var(--card-color) !important;
    border-radius: 10px;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;

    &__data {
      width: 80%;
      padding-top: calc(10 / 240 * 100%);
      padding-bottom: calc(5 / 240 * 100%);
      &__title {
        font-size: 20px;
        // font-weight: bold;
        line-height: 22px;
        color: var(--card-font-color) !important;
        text-align: left;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 820px) {
          font-size: 14px !important;
        }

        @media (max-width: 450px) {
          font-size: 18px !important;
        }

        @media (min-width: 1334px) {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__sub-title {
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        color: var(--card-font-color) !important;
        text-align: left;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-right: 10px;
        padding-left: 10px;
        display: block;
        -webkit-line-clamp: 1;

        //-webkit-box-orient: vertical;
        //overflow: hidden;
        //text-overflow: ellipsis;

        @media (max-width: 820px) {
          font-size: 10px !important;
          line-height: 11px !important;
        }

        @media (max-width: 450px) {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
      &__sub-title-title {
        position: absolute;
        bottom: 6px;
        font-size: 11px;
        font-weight: 300;
        line-height: 23px;
        color: var(--card-font-color) !important;
        text-align: left;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-right: 10px;
        padding-left: 10px;
        display: block;
        -webkit-line-clamp: 1;

        //-webkit-box-orient: vertical;
        //overflow: hidden;
        //text-overflow: ellipsis;

        @media (max-width: 820px) {
          font-size: 10px !important;
          line-height: 11px !important;
        }

        @media (max-width: 450px) {
          font-size: 12px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  &__contents {
    position: absolute;
    top: 0;
    width: 90%;
    height: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); */
    background: linear-gradient(270deg, #000000 0%, rgba(84, 84, 84, 0) 76.76%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &__data {
      width: 80%;

      &__title {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #fff;
        text-align: right;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-right: 20px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 820px) {
          font-size: 14px !important;
        }

        @media (max-width: 450px) {
          font-size: 18px !important;
        }

        @media (min-width: 1334px) {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__sub-title {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #fff;
        text-align: right;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-right: 20px;

        display: block;
        -webkit-line-clamp: 1;

        //-webkit-box-orient: vertical;
        //overflow: hidden;
        //text-overflow: ellipsis;

        @media (max-width: 820px) {
          font-size: 10px !important;
          line-height: 11px !important;
        }

        @media (max-width: 450px) {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .swiper {
    width: 100% !important;
    padding-bottom: 23px;

    @media (max-width: 768px) {
      margin-right: 20px;
      margin-left: 20px;
      padding-bottom: 23px;
    }

    @media (max-width: 450px) {
      margin-right: 20px;
      margin-left: 20px;
      padding-bottom: 23px;
    }

    .swiper-wrapper {
      .swiper-slide {
        //padding-bottom: 30px;

        @media (max-width: 991px) {
          margin-right: 14px !important;
        }

        @media (max-width: 768px) {
          margin-right: 14px !important;
        }

        @media (max-width: 767px) {
          margin-right: 14px !important;
        }

        //@media(max-width: 730px){
        //  margin-right: 127px !important;
        //}
        //@media(max-width: 707px){
        //  margin-right: 124px !important;
        //}
        //@media(max-width: 688px){
        //  margin-right: 122px !important;
        //}
        //@media(max-width: 678px){
        //  margin-right: 118px !important;
        //}
        //@media(max-width: 653px){
        //  margin-right: 110px !important;
        //}
        //@media(max-width: 612px){
        //  margin-right: 100px !important;
        //}
        //@media(max-width: 555px){
        //  margin-right: 94px !important;
        //}
        //@media(max-width: 510px){
        //  margin-right: 90px !important;
        //}
        //@media(max-width: 490px){
        //  margin-right: 84px !important;
        //}
        //@media(max-width: 450px){
        //  margin-right: 80px !important;
        //}
        //@media(max-width: 425px){
        //  margin-right: 76px !important;
        //}
        //@media(max-width: 397px){
        //  margin-right: 70px !important;
        //}
        //@media(max-width: 375px){
        //  margin-right: 65px !important;
        //}
        //@media(max-width: 320px){
        //  margin-right: 60px !important;
        //}
      }
    }

    .swiper-pagination {
      bottom: -8px;

      .swiper-pagination-bullet {
        width: 30px;
        background: #d1d1d1;
        border-radius: 3px;
        height: 3px;
      }

      .swiper-pagination-bullet-active {
        background: #6e6e6e;
      }
    }
  }

  .default-bg {
    //background: var(--primary-color);
    border-radius: 14px;

    .promotional-items__contents {
      //background: linear-gradient(270deg, var(--primary-color) 0%, var(--primary-color) 76.76%) !important;
    }
  }
}

.loyalty-items {
  display: flex;
  column-gap: 25px;
  border-bottom: 1px solid rgba(233, 233, 233, 0.5);

  //padding-bottom: 30px;
  @media (max-width: 768px) {
    //padding-bottom: 15px;
    position: relative;
    border-bottom: unset;

    &:before {
      content: '';
      position: absolute;
      width: 100% !important;
      height: 1px;
      //background: #e9e9e9;
      bottom: 0;
      margin: 0 auto;
      //left: 20px !important;
    }
  }

  @media (min-width: 426px) {
    //padding-bottom: 15px;
    position: relative;
    border-bottom: unset;
    //margin-top: 10px;

    &:before {
      content: '';
      position: absolute;
      width: 100% !important;
      height: 1px;
      //background: #e9e9e9;
      bottom: 0;
      margin: 0 auto;
      //left: 20px !important;
    }
  }

  @media (max-width: 767px) {
    //margin-top: 20px;
  }

  @media (max-width: 426px) {
    position: relative;
    border-bottom: unset;

    //padding-top: 20px;
    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 40px) !important;
      height: 1px;
      //background: #e9e9e9;
      bottom: 0;
      margin: 0 auto;
      left: 20px !important;
    }
  }

  &__card {
    position: relative;
    height: calc(240 / 240 * 100%);

    background: white !important;
    //width: 100% !important;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 100%;
      border-radius: 10px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      overflow: hidden;
    }
  }

  &__contents {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); */
    //background: linear-gradient(270deg, #000000 0%, rgba(84, 84, 84, 0) 76.76%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    .tint {
      z-index: 1;
      height: 100%;
      width: 60%;
      border-radius: 10px;
      /* top: 0; */
      position: absolute;
      background: linear-gradient(90deg, #000000 0%, rgba(84, 84, 84, 0) 91.76%);
    }

    &__data {
      width: 90%;
      z-index: 1;
      &__title {
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        color: #fff;
        text-align: right;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-right: 20px;

        text-transform: uppercase;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 820px) {
          font-size: 14px !important;
        }

        @media (max-width: 450px) {
          font-size: 18px !important;
        }

        @media (min-width: 1334px) {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__sub-title {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        color: #fff;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-right: 10px;
        padding-left: 10px;

        display: block;
        -webkit-line-clamp: 1;

        //-webkit-box-orient: vertical;
        //overflow: hidden;
        //text-overflow: ellipsis;
        @media (max-width: 1300px) {
          font-size: 12px !important;
          line-height: 16px !important;
        }
        @media (max-width: 820px) {
          font-size: 10px !important;
          line-height: 11px !important;
        }

        @media (max-width: 450px) {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .swiper {
    width: 100% !important;

    @media (max-width: 768px) {
      margin-right: 20px;
      margin-left: 20px;
    }

    @media (max-width: 450px) {
      margin-right: 20px;
      margin-left: 20px;
    }

    .swiper-wrapper {
      .swiper-slide {
        //padding-bottom: 30px;

        @media (max-width: 991px) {
          margin-right: 14px !important;
        }

        @media (max-width: 768px) {
          margin-right: 14px !important;
        }

        @media (max-width: 767px) {
          margin-right: 14px !important;
        }

        //@media(max-width: 730px){
        //  margin-right: 127px !important;
        //}
        //@media(max-width: 707px){
        //  margin-right: 124px !important;
        //}
        //@media(max-width: 688px){
        //  margin-right: 122px !important;
        //}
        //@media(max-width: 678px){
        //  margin-right: 118px !important;
        //}
        //@media(max-width: 653px){
        //  margin-right: 110px !important;
        //}
        //@media(max-width: 612px){
        //  margin-right: 100px !important;
        //}
        //@media(max-width: 555px){
        //  margin-right: 94px !important;
        //}
        //@media(max-width: 510px){
        //  margin-right: 90px !important;
        //}
        //@media(max-width: 490px){
        //  margin-right: 84px !important;
        //}
        //@media(max-width: 450px){
        //  margin-right: 80px !important;
        //}
        //@media(max-width: 425px){
        //  margin-right: 76px !important;
        //}
        //@media(max-width: 397px){
        //  margin-right: 70px !important;
        //}
        //@media(max-width: 375px){
        //  margin-right: 65px !important;
        //}
        //@media(max-width: 320px){
        //  margin-right: 60px !important;
        //}
      }
    }

    .swiper-pagination {
      bottom: 6px;

      .swiper-pagination-bullet {
        width: 30px;
        background: #d1d1d1;
        border-radius: 3px;
        height: 3px;
      }

      .swiper-pagination-bullet-active {
        background: #6e6e6e;
      }
    }
  }

  .default-bg {
    //background: var(--primary-color);
    border-radius: 10px;

    .promotional-items__contents {
      //background: linear-gradient(270deg, var(--primary-color) 0%, var(--primary-color) 76.76%) !important;
    }
  }
}

.promotional-item-without-category {
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

// Style for grid layout

.grid-layout-items {
  @media (max-width: 991px) {
    .row {
      column-gap: 0 !important;
      row-gap: 0 !important;

      .col-lg-6,
      .col-md-12 {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }
    }
  }

  @media (max-width: 425px) {
    .custom-category-name {
      //margin-left: 20px !important;
      padding-top: 30px;
      padding-bottom: 20px !important;

      &:before {
        height: calc(100% - 60px);
      }
    }
  }

  .row {
    row-gap: 20px;

    .card_layout_custom {
      padding-right: 16px !important;
      padding-left: 16px !important;

      @media (min-width: 992px) {
        margin-bottom: 0 !important;
      }

      @media (max-width: 768px) {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }

      @media (max-width: 767px) {
        padding-right: 12px !important;
        padding-left: 12px !important;
      }

      .hovernow {
        border-radius: 10px;
        overflow: hidden;
        border-bottom: unset;

        .grid-items-item {
          border-left: unset !important;
          padding: 0;
          //div{
          //  height: 100%;
          //}

          .disabled-item {
            opacity: 1;

            .col-md-9,
            .col-12 {
              .custom_item-name {
                opacity: 0.5;
                margin-bottom: 20px;
              }

              .custom_item-name-new {
                opacity: 0.5;
                margin-bottom: 55px;
              }

              .item-description {
                padding-top: 0;
                opacity: 0.5;
              }

              .item-description-new {
                padding-top: 0;
                opacity: 0.5;
              }

              .item-price {
                span {
                  opacity: 0.5;
                }

                .font-danger {
                  opacity: 1 !important;
                  color: #d63529 !important;

                  @media (max-width: 768px) {
                    //padding-top: 8px !important;
                  }
                }
              }
            }

            .item-image-rating-container-custom {
              img {
                opacity: 0.5;
              }
            }
          }

          .row {
            height: 100%;

            .col-md-9,
            .col-12 {
              padding: 15px !important;
              margin-left: 1px;

              @media (max-width: 768px) {
                //padding: 20px !important;
                padding: 20px 0px 20px 10px !important;
              }

              .custom_item-name {
                display: flex;
                align-items: flex-start;
                column-gap: 8px;
                margin-bottom: 5px;
                padding-right: 25px;

                h1 {
                  font-weight: 900 !important;
                  font-size: 15px !important;
                }

                p {
                  font-weight: 600 !important;
                  max-width: 100% !important;
                }

                h2 {
                  font-size: 16px;

                  font-weight: 500 !important;
                  line-height: 20px;
                  color: #2e2e2e;
                  margin: 0;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: calc(100% - 60px);

                  //max-height: 40px;
                  @media (max-width: 768px) {
                    height: unset !important;
                  }
                }

                .item-quantity {
                  width: 22px;
                  height: 20px;
                  background: var(--primary-color);
                  border-radius: 3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  h4 {
                    margin: 0;

                    line-height: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #ffffff;
                  }
                }
              }

              .custom_item-name-new {
                display: flex;
                align-items: flex-start;
                column-gap: 8px;
                margin-bottom: 55px;

                h2 {
                  font-size: 16px;

                  font-weight: 500 !important;
                  line-height: 20px;
                  color: #2e2e2e;
                  margin: 0;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: calc(100% - 60px);

                  //max-height: 40px;
                  @media (max-width: 768px) {
                    height: unset !important;
                  }
                }

                .item-quantity {
                  width: 22px;
                  height: 20px;
                  background: var(--primary-color);
                  border-radius: 3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  h4 {
                    margin: 0;

                    line-height: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #ffffff;
                  }
                }
              }

              .item-description {
                padding-top: 0;
                margin-bottom: 0;
                margin-bottom: 20px;
                padding-right: 15px;

                h2 {
                  color: #bbb9c7;

                  font-size: 14px;
                  font-weight: 340;
                  line-height: 16px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0;
                  margin-right: 30px;
                }

                @media (max-width: 450px) {
                  padding-right: 0px !important;

                  h2 {
                    -webkit-line-clamp: 2;
                    //display: block !important;
                    //margin-right: 0px !important;
                  }
                }
              }

              .item-description-new {
                padding-top: 0;
                margin-bottom: 20px;
                padding-right: 15px;

                h2 {
                  color: #bbb9c7;

                  font-size: 14px;
                  font-weight: 300;
                  line-height: 18px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0;
                  margin-right: 15px;
                }

                @media (max-width: 450px) {
                  padding-right: 0px !important;

                  h2 {
                    -webkit-line-clamp: 2;
                    //display: block !important;
                    //margin-right: 0px !important;
                  }
                }
              }

              .price-view {
                position: absolute;
                bottom: 10px;
              }

              .item-price {
                //position: absolute;
                //bottom: 10px;
                @media (min-width: 991px) and (max-width: 1200px) {
                  align-items: baseline;
                  display: flex;
                  margin-left: -6px;
                  flex-direction: column;
                }

                .font-weight-bold {
                  font-weight: normal !important;
                  padding-left: 4px !important;
                }

                span {
                  font-size: 14px;
                  font-weight: normal;
                  color: #000000;
                  line-height: 20px;
                }

                @media (min-width: 1200px) and (max-width: 1300px) {
                  span {
                    font-size: 14px !important;
                  }
                }

                @media (max-width: 922px) {
                  span {
                    font-size: 14px !important;
                  }
                }

                .text-decoration-line-through {
                  color: var(--card-subtitle-color);
                  padding-left: 4px;
                }
              }
            }

            .item-image-rating-container-custom {
              width: 140px !important;
              height: 100% !important;
              position: absolute;
              top: 0;
              right: 0;
              //padding-top: calc(120 / 140 * 100%);
              object-fit: cover;

              .item-image {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                pointer-events: none;
              }

              @media (min-width: 450px) {
                .item-image {
                  border-bottom-right-radius: 0;
                  border-bottom-left-radius: 0;
                  border-top-left-radius: 0;
                }
                .item-quick-img {
                  border-radius: 10px !important;
                }
              }
              .quick-btn {
                height: 85% !important;
                width: 85% !important;
                margin: 10px !important;
              }

              .lazy-load-image-background,
              .lazy-load-image-loaded {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-top-left-radius: unset !important;
                border-bottom-left-radius: unset !important;

                img {
                  width: 100% !important;
                  height: 100% !important;
                }
              }

              .rating-container-custom {
                .quick-rating-right {
                  right: 11px !important;
                }
                .quick-btn-right {
                  right: 0px !important;
                  top: 20px !important;
                  @media (max-width: 450px) {
                    right: 11px !important;
                    top: 20px !important;
                  }
                }
                .rating {
                  position: absolute;
                  right: 0px;
                  top: 15px;
                  color: #fff;
                  height: 24px;
                  display: flex;
                  align-items: center;

                  //@media (min-width: 450px) {
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  //}

                  .rating-number {
                    margin-left: 4px;
                    font-weight: 400;
                  }

                  &.badge {
                    min-width: 50px;
                    font-size: 14px;
                    border-radius: 3em;
                    padding-bottom: 4px;
                  }
                }
              }
            }

            .col-md-3,
            .col-4 {
              .item-image-rating-container-custom {
                width: 140px !important;
                height: 100% !important;
                position: absolute;
                top: 0;
                right: 0;
                //padding-top: calc(120 / 140 * 100%);

                .item-image {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  object-fit: cover;
                  top: 0;
                  left: 0;
                  pointer-events: none;
                }

                @media (min-width: 450px) {
                  .item-image {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                  }
                  .item-quick-img {
                    border-radius: 10px !important;
                  }
                }

                .quick-btn {
                  height: 85% !important;
                  width: 85% !important;
                  margin: 10px !important;
                }

                .lazy-load-image-background,
                .lazy-load-image-loaded {
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-top-left-radius: unset !important;
                  border-bottom-left-radius: unset !important;

                  @media (max-width: 450px) {
                    height: 120px !important;
                    width: 120px !important;
                    margin: 10px !important;
                  }

                  img {
                    width: 100% !important;
                    height: 100% !important;
                  }
                }

                .rating-container-custom {
                  .quick-rating-right {
                    right: 11px !important;
                  }
                  .quick-btn-right {
                    @media (max-width: 450px) {
                      right: 11px !important;
                      top: 20px !important;
                    }
                  }
                  .rating {
                    position: absolute;
                    right: 0px;
                    top: 15px;
                    color: #fff;
                    height: 24px;
                    display: flex;
                    align-items: center;

                    // @media (max-width: 450px) {
                    //   right: 11px !important;
                    // }

                    //@media (min-width: 450px) {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    //}

                    .rating-number {
                      margin-left: 4px;
                      font-weight: 400;
                    }

                    &.badge {
                      min-width: 50px;
                      font-size: 14px;
                      border-radius: 3em;
                      padding-bottom: 4px;
                    }
                  }
                }
              }
            }
          }

          .item-row-section {
            .item-row-section-first-item {
              position: relative;

              .item-price {
                position: absolute;
                bottom: 24px;

                .text-decoration-line-through {
                  color: var(--card-subtitle-color) !important;
                  padding-left: 4px;
                }
              }
            }
          }
        }

        .item-min-height {
          min-height: 100px;

          @media (max-width: 767px) {
            .row {
              min-height: 100px;
            }
          }
        }

        .item-max-height {
          min-height: 140px;

          .row {
            .col-8,
            .col-md-9 {
              height: 100%;
            }
          }

          @media (max-width: 767px) {
            min-height: 140px !important;

            .row {
              min-height: 140px;

              .col-8,
              .col-md-9 {
                min-height: 100% !important;
                height: unset !important;
                padding: 20px 0px 20px 10px !important;
              }
            }
          }
        }

        @media (max-width: 991px) {
          border-radius: 0 !important;
          border-bottom: 1px solid #d1d1d1;
        }
      }
    }
  }
}

.large-grid-layout {
  @media (max-width: 768px) {
    //padding-left: 35px;
    //padding-right: 25px;
    .category-name {
      //margin-left: -15px;
      padding-top: 20px;
      padding-bottom: 20px;

      &:before {
        height: calc(100% - 40px);
      }
    }

    .container-fluid {
      @media (max-width: 768px) {
        padding-left: 15px !important;

        .row {
          column-gap: 20px;
          padding-left: 0 !important;

          .col-md-6,
          .col-lg-4 {
            width: calc(50% - 17px);
            flex: 0 0 calc(50% - 17px);
            padding-left: 15px;
          }
        }
      }

      .row {
        padding-left: 5px;
      }
    }
  }

  @media (max-width: 450px) {
    // padding-right: 35px;
    // padding-left: 23px;
    .container-fluid {
      .row {
        column-gap: 20px;

        .col-lg-4,
        .col-md-6 {
          width: calc(100% - 10px);
          flex: 0 0 calc(100% - 10px);
          max-width: 100%;
          padding-right: 5px;
        }
      }
    }
  }

  .row {
    column-gap: 25px;
    row-gap: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .card_layout_custom {
    padding: 0;
    flex: 0 0 calc(50% - 18px);
    width: calc(50% - 17px);
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    //&:nth-of-type(2n){
    //  padding: 0 25px;
    //}
    //&:nth-of-type(2n+1){
    //  padding-right: 0;
    //}
    .hovernow {
      border-radius: 10px;
      overflow: hidden;
      border-bottom: unset;

      height: 100%;
      position: relative;

      .SwipeableListItem_swipeableListItem__3xnuO {
        height: 100%;
      }

      .grid-items-item {
        padding: 0;
        border-left: 0 !important;
        height: 100% !important;

        .disabled-item {
          opacity: 1;

          .custom_item-name {
            opacity: 0.5;
            margin-bottom: 20px;

            p {
              height: unset;
            }
          }

          .custom_item-name-new {
            opacity: 0.5;
            margin-bottom: 55px;

            p {
              height: unset;
            }
          }

          .item-description {
            padding-top: 0;
            opacity: 0.5;
          }

          .item-description-new {
            padding-top: 0;
            opacity: 0.5;
          }

          .item-price {
            span {
              opacity: 0.5;
            }

            .font-danger {
              opacity: 1 !important;
            }
          }

          .item-row-section-second-item {
            opacity: 0.5;
          }
        }

        .item-row-section {
          display: flex;
          flex-direction: column-reverse;
          height: 100%;

          .col-md-6,
          .col-md-4 {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;

            @media (max-width: 768px) {
              padding-left: 10px;
            }
          }

          .custom_item-name {
            display: flex;
            align-items: flex-start;
            column-gap: 8px;
            margin-bottom: 20px;

            //height: 55px;
            @media (max-width: 768px) {
              padding-bottom: 15px;

              h2 {
                line-height: 16px;
              }
            }

            @media (max-width: 425px) {
              padding-bottom: 15px;
            }

            h2 {
              font-size: 16px;

              font-weight: 500 !important;
              line-height: 20px;
              color: #2e2e2e;
              margin: 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 768px) {
                font-size: 14px;
              }
            }

            .item-quantity {
              width: 22px;
              height: 20px;
              background: var(--primary-color);
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 3px 6px;

              h4 {
                margin: 0;

                line-height: 15px;
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }

          .custom_item-name-new {
            display: flex;
            align-items: flex-start;
            column-gap: 8px;
            margin-bottom: 5px;

            //height: 55px;
            @media (max-width: 768px) {
              padding-bottom: 15px;

              h2 {
                line-height: 16px;
              }
            }

            @media (max-width: 450px) {
              padding-bottom: 0px !important;
            }

            p {
              font-size: 16px;

              font-weight: 700 !important;
              line-height: 20px;
              color: #2e2e2e;
              margin: 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 768px) {
                font-size: 20px;
              }
            }

            .item-quantity {
              width: 22px;
              height: 20px;
              background: var(--primary-color);
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 3px 6px;

              h4 {
                margin: 0;

                line-height: 15px;
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }

          .item-description {
            padding-top: 0;

            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            margin-text-outline: 20px;
            margin-bottom: 0;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }

          .item-description-new {
            padding-top: 0;

            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            margin-text-outline: 20px;
          }

          .item-row-section-first-item {
            padding: 14px 20px 20px 20px;
            height: 100%;

            @media (max-width: 768px) {
              padding: 0px 20px 13px 10px !important;
            }

            .item-description {
              display: block;
            }

            .item-description-new {
              display: block;
            }

            .item-price {
              //position: absolute;
              //bottom: 15px;

              .font-weight-bold {
                font-weight: normal !important;
              }

              .text-decoration-line-through {
                color: var(--card-subtitle-color) !important;
                padding-left: 4px;
              }

              @media (max-width: 768px) {
                font-weight: normal;
                font-size: 18px;
                line-height: 18px;
                //display: flex;

                flex-direction: column;
                align-items: flex-start;

                svg {
                  //display: none;
                }
              }
            }
          }

          .item-row-section-second-item {
            .item-image-rating-container-custom {
              width: 100%;

              .item-image {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                pointer-events: none;
              }

              .lazy-load-image-loaded,
              .lazy-load-image-background {
                position: relative;
                padding-top: calc(300 / 370 * 100%);
                width: 100% !important;
                object-fit: cover;

                .item-image {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-bottom-right-radius: 0;
                  border-bottom-left-radius: 0;
                  pointer-events: none;
                }
              }

              .rating-container-custom {
                .rating {
                  position: absolute;
                  right: 0;
                  top: 15px;
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  column-gap: 4px;

                  .rating-number {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;

                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.small-grid-layout {
  @media (max-width: 768px) {
    //padding-left: 35px;
    //padding-right: 25px;
    .category-name {
      //margin-left: -15px;
      padding-top: 20px;
      padding-bottom: 20px;

      &:before {
        height: calc(100% - 40px);
      }
    }

    @media (max-width: 920px) {
      .container-fluid {
        .row {
          grid-row-gap: 10px;
          row-gap: 10px;
        }
      }
    }

    .container-fluid {
      @media (max-width: 768px) {
        padding-left: 15px !important;

        .row {
          column-gap: 10px;
          grid-row-gap: 10px;
          row-gap: 10px;
          //padding-left: 13px !important;

          .col-md-6,
          .col-lg-4 {
            width: calc(50% - 17px);
            flex: 0 0 calc(50% - 16px);
          }
        }
      }

      .row {
        padding-left: 5px;
      }
    }
  }

  @media (max-width: 426px) {
    // padding-right: 35px;
    // padding-left: 23px;
    padding-right: 15px !important;

    .container-fluid {
      .row {
        column-gap: 10px;

        .col-lg-4,
        .col-md-6 {
          width: calc(50% - 10px);
          flex: 0 0 calc(50% - 0px);
        }
      }
    }
  }

  .row {
    column-gap: 25px;
    row-gap: 25px;
    padding-left: 15px;
  }

  .card_layout_custom {
    padding: 0;
    // flex: 0 0 calc(33% - 18px);
    // width: calc(33% - 17px);
    flex: 0 0 calc(24% - 18px);
    width: calc(24% - 17px);
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    //&:nth-of-type(2n){
    //  padding: 0 25px;
    //}
    //&:nth-of-type(2n+1){
    //  padding-right: 0;
    //}

    @media (max-width: 1300px) {
      flex: 0 0 calc(33% - 22px);
      width: calc(33% - 20px);
    }

    @media (max-width: 1000px) {
      flex: 0 0 calc(50% - 22px);
      width: calc(50% - 20px);
    }

    .hovernow {
      border-radius: 10px;
      overflow: hidden;
      border-bottom: unset;

      height: 100%;
      position: relative;

      .SwipeableListItem_swipeableListItem__3xnuO {
        height: 100%;
      }

      .grid-items-item {
        padding: 0;
        border-left: 0 !important;
        height: 100% !important;

        .disabled-item {
          opacity: 1;

          .custom_item-name {
            opacity: 0.5;
            margin-bottom: 20px;
          }

          .custom_item-name-new {
            opacity: 0.5;
            //margin-bottom: 55px;
          }

          .item-description {
            padding-top: 0;
            opacity: 0.5;
          }

          .item-description-new {
            padding-top: 0;
            opacity: 0.5;
          }

          .item-price {
            span {
              opacity: 0.5;
            }

            .font-danger {
              opacity: 1 !important;
            }
          }

          .item-row-section-second-item {
            opacity: 0.5;
          }
        }

        .item-row-section {
          display: flex;
          flex-direction: column-reverse;
          height: 100%;

          .col-md-6,
          .col-md-4 {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;

            @media (max-width: 768px) {
              padding-left: 10px;
            }
          }

          .custom_item-name {
            display: flex;
            align-items: flex-start;
            column-gap: 8px;
            margin-bottom: 20px;

            //height: 55px;
            @media (max-width: 768px) {
              padding-bottom: 15px;

              h2 {
                line-height: 16px;
              }

              p {
                max-width: 100%;
              }
            }

            @media (max-width: 425px) {
              padding-bottom: 15px;
            }

            h2 {
              font-size: 16px;

              font-weight: 500 !important;
              line-height: 20px;
              color: #2e2e2e;
              margin: 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 768px) {
                font-size: 14px;
              }
            }

            .item-quantity {
              width: 22px;
              height: 20px;
              background: var(--primary-color);
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 3px 6px;

              h4 {
                margin: 0;

                line-height: 15px;
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }

          .custom_item-name-new {
            display: flex;
            align-items: flex-start;
            column-gap: 8px;
            margin-bottom: 5px;

            h1 {
              font-weight: 900 !important;
              font-size: 15px !important;
            }

            p {
              font-weight: 900 !important;
              font-size: 15px !important;
            }

            //height: 55px;
            @media (max-width: 768px) {
              //padding-bottom: 15px;

              h2 {
                line-height: 16px;
              }

              p {
                max-width: 100%;
              }
            }

            @media (max-width: 1120px) {
              p {
                font-size: 14px !important;
              }
            }

            @media (max-width: 425px) {
              //padding-bottom: 15px;

              p {
                font-size: 14px !important;
              }
            }

            h2 {
              font-size: 16px;

              font-weight: 500 !important;
              line-height: 20px;
              color: #2e2e2e;
              margin: 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 768px) {
                font-size: 14px;
              }
            }

            .item-quantity {
              width: 22px;
              height: 20px;
              background: var(--primary-color);
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 3px 6px;

              h4 {
                margin: 0;

                line-height: 15px;
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }

          .item-description {
            padding-top: 0;

            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
            margin-text-outline: 20px;
            margin-bottom: 0;
            padding-bottom: 20px;
          }

          .item-description-new {
            padding-top: 0;

            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            margin-text-outline: 20px;
            padding-bottom: 20px;
          }

          .item-row-section-first-item {
            padding: 14px 20px 20px 20px;
            height: 100%;

            @media (max-width: 768px) {
              padding: 15px 10px 10px 10px !important;
            }

            .item-description {
              //display: none;
            }

            .item-description-new {
              //display: none;
            }

            .item-price {
              //position: absolute;
              //bottom: 15px;

              font-size: 14px !important;

              .font-weight-bold {
                font-weight: normal !important;
              }

              .text-decoration-line-through {
                color: var(--card-subtitle-color) !important;
                padding-left: 4px;
              }

              @media (max-width: 768px) {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                //display: flex;

                flex-direction: row;
                align-items: flex-start;

                svg {
                  //display: none;
                }
              }

              @media (max-width: 1150px) {
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                //display: flex;

                flex-direction: row;
                align-items: flex-start;

                svg {
                  //display: none;
                }
              }

              @media (max-width: 920px) {
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                //display: flex;

                flex-direction: row;
                align-items: flex-start;

                svg {
                  //display: none;
                }
              }

              @media (max-width: 426px) {
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                //display: flex;

                flex-direction: row;
                align-items: flex-start;

                svg {
                  //display: none;
                }
              }
            }
          }

          .item-row-section-second-item {
            .item-image-rating-container-custom {
              width: 100%;

              .item-image {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                pointer-events: none;
              }

              .lazy-load-image-loaded,
              .lazy-load-image-background {
                position: relative;
                padding-top: calc(200 / 240 * 100%);
                width: 100% !important;
                object-fit: cover;

                .item-image {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-bottom-right-radius: 0;
                  border-bottom-left-radius: 0;
                  pointer-events: none;
                }
              }

              .rating-container-custom {
                .rating {
                  position: absolute;
                  right: 0;
                  top: 15px;
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  column-gap: 4px;

                  .rating-number {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;

                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.item-with-min-height {
  @media (max-width: 767px) {
    min-height: 140px !important;

    .col-8,
    .col-md-9 {
      min-height: 100% !important;
      height: unset !important;
    }
  }
}

// Style for order preference modal
.order-pref-modal {
  .modal-title {
  }

  button {
  }

  .modal-radio-title {
    .radio-tile-label {
    }
  }

  .text-primary {
  }

  .cursor {
  }

  .text-bold,
  .cursor,
  .font-danger {
  }

  .card {
    .row {
      margin-right: 0;
      margin-left: 0;
    }
  }

  // .ps-3 {
  // .time-field {
  //   padding-right: 20px;
  //   padding-left: 20px;
  // }
  // }
  .order-types {
    .time-field {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.old-order-summary {
  .text-primary,
  .gst,
  .grand-total {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.custom-order-prev-modal {
  .ps-3 {
    padding: 0 20px;
  }
}

//style for item detail modal
.item-detail-modal {
  .modal-content {
    .modal-body {
      background: #f9f9f9;

      .item-detail {
        .f0,
        .item-price,
        .item-description,
        .item-description-new {
        }

        .sticky-top {
          position: unset;
        }

        .item-description,
        .item-description-new {
          background: #ffffff;
          //box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
        }

        .item-price {
          .text-decoration-line-through {
            color: var(--card-subtitle-color) !important;
            padding-left: 4px;
          }
        }
      }

      .item-options {
        .comments {
          //box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06) !important;
          background: #f9f9f9;

          .form-control {
            max-height: 80px !important;
          }
        }

        .item-options-container {
          .item-option {
            .option-name,
            .option-required,
            .text-danger {
            }

            .option-values {
              .option-value {
                .option-value-name,
                .option-value-price,
                .form-check-input {
                }
              }
            }

            .comments {
              box-shadow: 0px 3px 20px 0 rgba(0, 0, 0, 0.06);

              .form-control {
                max-height: 80px;
              }
            }

            .quantity-container {
              .item-quantity {
              }
            }
          }
        }
      }

      .modal-footer {
        @media (max-width: 425px) {
          border-radius: unset !important;
        }

        button {
        }
      }

      .item-intro {
        position: unset !important;

        .item-name {
          justify-content: flex-start !important;
          padding-left: 0;
        }

        .item-price {
          justify-content: flex-end !important;
          column-gap: 5px;
          flex-direction: column;
          align-items: flex-end;
          row-gap: 5px;

          .font-weight-bold {
            font-weight: normal !important;
          }
        }
      }
    }
  }

  .modal-footer {
    @media (max-width: 475px) {
      border-radius: unset !important;
      border-top: unset !important;

      .container-fluid {
        .row {
          column-gap: 14px !important;
        }
      }
    }

    @media (max-width: 409px) {
      .row {
        column-gap: 28px !important;
      }
    }

    @media (max-width: 404px) {
      .row {
        column-gap: 22px !important;
      }
    }

    @media (max-width: 375px) {
      .row {
        column-gap: 8px !important;

        .item-modal-add-item {
          h2 {
            font-size: 14px !important;
          }
        }
      }
    }

    button {
    }
  }
}

// order summary
@mixin common-order-summary-padding {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.order-summary-cart-col {
  .order-summary-cart-list-col {
    box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px !important;
    padding: 0 !important;
    flex-direction: column;

    .order-summary-header {
      font-size: 18px;
      font-weight: normal;
      line-height: 20px;

      color: #2e2e2e;
      padding: 15px 15px 15px 30px;
      position: relative;

      //border-bottom: 1px solid #E9E9E9;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 30px;
        width: calc(100% - 60px);
        background-color: var(--highlight-color);
        height: 1px;
      }
    }

    .add-to-order {
      position: relative;
      padding-left: 30px !important;
      padding-right: 30px !important;

      button {
        border-color: var(--highlight-color) !important;
        color: var(--highlight-color) !important;

        &:hover {
          background: var(--highlight-color) !important;
          color: var(--highlight-text-color) !important;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        width: calc(100% - 60px);
        background-color: var(--highlight-color);
        height: 1px;
      }
    }

    .comments {
      @include common-order-summary-padding;

      input {
        background-color: var(--card-color) !important;
        border-bottom-color: var(--highlight-color) !important;
      }
    }

    .promo-comments {
      padding: 0px 0px 10px !important;
    }

    .gst {
      @include common-order-summary-padding;
      padding-top: 10px !important;

      .text-bold {
        font-size: 14px;

        font-weight: normal !important;
        line-height: 16px;
      }

      .discount-price {
        color: #d63529 !important;
      }
    }

    .grand-total {
      @include common-order-summary-padding;
      padding-top: 10px !important;

      .text-bold {
        font-size: 14px;

        font-weight: bold !important;
        line-height: 16px;
      }
    }

    .menu-cart-list {
      max-height: unset !important;
      min-height: unset !important;
      overflow-y: unset !important;
      @include common-menu-cart-list-style;
      position: relative;
      @include common-order-summary-padding;

      .line-item-container-custom {
        padding-top: 15px !important;
        padding-bottom: 15px !important;

        &:nth-of-type(1) {
          padding-top: 20px !important;
        }

        &:last-of-type {
          padding-bottom: 20px !important;
        }
      }

      //&:before{
      //  content: "";
      //  position: absolute;
      //  top: 0;
      //  left: 20px;
      //  width: calc(100% - 40px);
      //  background-color: #E9E9E9;
      //  height: 1px;
      //}
      //&:after{
      //  content: "";
      //  position: absolute;
      //  bottom: 0;
      //  left: 20px;
      //  width: calc(100% - 40px);
      //  background-color: #E9E9E9;
      //  height: 1px;
      //}
    }

    .line-item-container-custom {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      padding-right: 30px !important;
      padding-left: 30px !important;
      position: relative;
      //   border-bottom: unset !important;

      &:nth-of-type(1) {
        padding-top: 20px !important;
      }

      &:last-of-type {
        padding-bottom: 20px !important;
      }

      .line-item {
        .quantity {
          font-size: 12px;
          font-weight: normal;

          line-height: 15px;
          width: 22px;
          height: 20px;
          background: var(--primary-color);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          color: #ffffff;
          margin-right: 10px;
        }

        .name {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;

          .text-muted {
            padding-top: 5px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            color: #6e6e6e;
          }
        }

        .price {
          display: flex;
          flex-direction: column;
          column-gap: 5px;
          font-size: 14px;
          font-weight: normal !important;

          line-height: 18px;
          align-items: flex-end;
        }
      }
    }

    .apply-promo-btn {
      padding-right: 30px !important;
    }

    .cart-footer {
      position: sticky !important;
      bottom: 0;

      //margin-bottom: 15px;
      @media (max-width: 767px) {
        position: fixed !important;
        bottom: 0;
        left: 0;
        border-radius: unset !important;
        display: flex !important;
        margin-bottom: 0 !important;
      }
    }

    @media (max-width: 425px) {
      border-radius: 0 !important;
    }
  }

  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 0;
  }
}

// item modal details

@mixin item-modifier-quantity {
  background: var(--primary-color) !important;
  width: 24px;
  height: 22px;
  border-radius: 3px;
  font-size: 14px;

  font-weight: normal;
  line-height: 18px;
  color: #ffffff;
  padding: 2px 6px;
}

@mixin item-price {
  font-size: 20px;
  font-weight: normal !important;
  line-height: 25px;

  color: #2e2e2e;
  display: flex;
  align-items: center;
}

@mixin item-modifier-menu-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;

  color: #2e2e2e !important;
}

.item-detail-modal {
  .item-detail {
    .item-banner {
      //position: relative;
      //padding-top: calc(260 / 570 * 100%);
      //object-fit: cover;
      margin-bottom: 0;

      .banner-wrapper {
        //position: absolute;
        //top: 0;
        //width: 100%;
        //height: 100%;
        cursor: pointer;

        .carousel .control-dots {
          bottom: 15px;
        }
      }

      .control-dots {
        display: none;
      }
    }

    .item-rating {
      width: fit-content;
      top: 20px;
      left: 20px !important;
      padding-left: 0px;
      padding-right: 0px;
      max-width: 500px;
      position: absolute;
      z-index: 1031;
      text-align: right;

      .rating-container-custom {
        .rating {
          display: flex;
          align-items: center;
          column-gap: 8px;
          padding: 5px 15px !important;
          border-radius: 15px !important;

          .rating-number {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;

            color: #ffffff;
            margin-left: 0;
          }
        }
      }
    }

    .rating-bg-custom {
      position: absolute;
      top: 15px;
      left: 20px;

      .rating-container-custom {
        .rating {
          display: flex;
          column-gap: 8px;
          border-radius: 15px;

          .rating-number {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }

    .item-modifier-without-photo-name {
      margin-top: 42px !important;
      padding: 0 20px;

      .item-modifier-quantity {
        @include item-modifier-quantity;
      }

      .item-price {
        .font-weight-bold {
          font-weight: normal !important;
        }

        @include item-price;

        @media (max-width: 475px) {
          flex-direction: column;
          row-gap: 5px;
        }

        align-items: flex-start;
        column-gap: 5px;
        grid-row-gap: 5px;

        .text-decoration-line-through {
          font-weight: 300;
        }
      }

      @media (max-width: 350px) {
        .f0 {
          display: flex;
          align-items: flex-start;
          column-gap: 10px;

          .item-modifier-menu-name {
            font-size: 16px;
          }
        }

        .item-price {
          font-size: 16px;

          .font-weight-bold {
            font-weight: normal !important;
          }

          .text-decoration-line-through {
            font-weight: 300;
          }
        }
      }
    }

    .item-intro {
      padding: 30px 20px 20px 20px !important;

      .item-name {
        display: flex;
        align-items: flex-start;
        column-gap: 10px;
        justify-content: flex-end;

        .item-modifier-quantity {
          @include item-modifier-quantity;
        }
      }

      .item-price {
        .font-weight-bold {
          font-weight: normal !important;
        }

        @include item-price;

        .text-decoration-line-through {
          font-weight: 300;
        }
      }
    }

    .item-description {
      padding: 0px 20px 30px 20px;
      font-size: 16px;

      line-height: 20px;
      font-weight: 300 !important;
    }

    .item-description-new {
      padding: 0px 20px 30px 20px;
      font-size: 16px;

      line-height: 20px;
      font-weight: 300 !important;
    }
  }

  .item-options {
    .special-instruction {
      margin: 0;
      background: #f9f9f9;
      padding: 15px 30px 20px 30px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #2e2e2e;
    }

    .special-instruction-without-option {
      padding: 20px 30px !important;
    }

    .item-options-container-custom {
      .item-option {
        .option-name-1 {
          padding: 10px 17px !important;
        }

        .option-name {
          width: 100%;
          display: flex !important;
          justify-content: space-between;
          //   align-items: center;
          padding: 15px 30px 20px 17px;
          background: #f9f9f9;
          border-top: unset !important;
          border-bottom: unset !important;

          .option-name-title {
            display: flex;
            justify-content: flex-start;
            font-size: 16px;
            font-weight: 900;
            line-height: 20px;

            color: #2e2e2e;
          }

          .option-required {
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            color: #6e6e6e;
          }
        }
      }

      .option-values {
        // padding: 20px 30px 0px 30px !important;
        box-shadow: 0px 3px 20px 0 rgba(0, 0, 0, 0.06);
        margin-bottom: 5px;

        .option-value {
          margin-bottom: 0 !important;
          padding-bottom: 20px !important;
          cursor: pointer;

          .option-value-name {
            display: flex;
            align-items: center;
            column-gap: 15px;

            h2 {
              margin: 0;
              font-size: 16px;

              line-height: 16px;
              color: #6e6e6e;
              font-weight: normal;
            }

            //input[type="radio"]{
            //  accent-color: #EB4432;
            //}
          }

          .option-value-price {
            margin-right: 0 !important;
            font-size: 16px;

            line-height: 15px;
            font-weight: normal !important;
          }

          .form-check-input {
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 0;
          }
        }

        .option-value-select {
          .option-value-name {
            column-gap: 10px;

            .option-value-quantity {
              font-size: 16px;
              font-weight: normal;
              line-height: 20px;
              color: var(--button-color);
            }
          }

          .option-value-control {
            display: flex;
            align-items: center;
            column-gap: 20px;
          }
        }
      }
    }

    .comments {
      padding: 0px 20px 30px 25px !important;

      .form-control {
        padding: 15px !important;
        border: 1px solid rgba(209, 209, 209, 1);
        border-radius: 6px;

        &::placeholder {
          color: #b3b3b3;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 18px;
        }
      }
    }

    .item-option {
      .option-name {
        padding: 1rem;
        background-color: #eeeeee;
      }

      .option-required {
        margin-left: 0.2rem;
        font-weight: normal;
        font-style: italic;
      }

      .option-values {
        padding: 1rem;

        .option-value {
          display: flex;
          justify-content: space-between;
          padding-bottom: 1rem;
          cursor: pointer;

          .option-value-name {
            width: 60%;

            .option-value-name-title-pl {
              padding-left: 15px;
              cursor: pointer;
            }

            .option-value-radio {
              position: relative;

              input:checked ~ .checkmark {
                background: var(--highlight-color);
                border: unset;
              }

              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
              }

              .checkmark {
                position: absolute;
                top: -10px;
                left: 0;
                height: 18px;
                width: 18px;
                /* background-color: #eee; */
                border-radius: 50%;
                background: #fff;
                border: 1px solid #6e6e6e;

                &:after {
                  content: '';
                  position: absolute;
                  display: none;
                }

                &:before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 52%;
                  transform: translate(-50%, -50%);
                  background: #ffffff;
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                }
              }

              input:checked ~ .checkmark:after {
                display: block;
              }
            }

            .option-value-select {
              position: relative;

              input:checked ~ .checkmark {
                background: var(--button-color);
                border: unset;
              }

              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
              }

              .checkmark {
                position: absolute;
                top: -10px;
                left: 0;
                height: 18px;
                width: 18px;
                background-color: #ffffff;
                border: 1px solid #707070;
                border-radius: 3px;

                &:after {
                  content: '';
                  display: none;
                  position: absolute;
                  left: 56%;
                  top: 44%;
                  width: 5px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  transform: translate(-50%, -50%) rotate(45deg);
                }
              }

              input:checked ~ .checkmark:after {
                display: block;
              }
            }
          }

          .option-value-quantity {
            color: var(--button-color);
            font-size: 1.4rem;
          }

          .option-value-price {
            margin-right: 2rem;
            font-weight: normal;
            color: gray;
          }

          .form-check-input {
            transform: scale(1.2);
            position: inherit;
          }
        }
      }
    }

    .select-options {
      position: fixed;
      max-width: $max-width;
      bottom: 0;
      text-align: center;
      color: #fff;
      background-color: var(--primary-color);
      padding: 0.8rem;
      width: 100%;
    }
  }

  .modal-content {
    border-radius: 13px;
    border: unset !important;

    .close {
      right: 20px;
      top: 20px;
      position: absolute;
      z-index: 99999;
      opacity: 1;
      /* opacity: 0.7; */
      width: 30px;
      height: 30px;
      border-radius: 3px;
      border: 1px solid rgba(209, 209, 209, 0.5) !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-body {
    border-radius: 10px;

    @media (max-width: 500px) {
      border-radius: unset !important;
    }
  }

  .modal-footer {
    padding: 10px 20px 10px 20px;
    background: #f9f9f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media (max-width: 768px) {
      padding: 10px 30px 10px 35px;
    }

    @media (max-width: 500px) {
      border-radius: unset !important;
    }

    @media (max-width: 475px) {
      box-shadow: 0px -3px 20px 0 rgba(0, 0, 0, 0.2);
      z-index: 1 !important;
      padding: 10px 10px 10px 10px !important;

      .container-fluid {
        .row {
          flex-direction: column !important;
        }
      }
    }

    @media (max-width: 375px) {
    }

    .container-fluid {
      padding: 0;
      margin: 0;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 20px;

        @media (max-width: 475px) {
          justify-content: space-between;
        }

        @media (max-width: 414px) {
          justify-content: unset !important;

          .item-modal-add-item {
            h2 {
              .font-color-white {
                padding-left: 0 !important;
              }
            }
          }
        }

        @media (max-width: 350px) {
          column-gap: 20px;
        }

        @media (max-width: 399px) {
          justify-content: unset !important;
          column-gap: 20px;

          .item-modal-add-item {
            width: 60%;
            column-gap: 2px;
          }
        }

        @media (max-width: 392px) {
          justify-content: unset !important;
          column-gap: 20px;

          .item-modal-add-item {
            width: 61%;
          }
        }

        .add-order {
          display: flex;
          gap: 50px;

          @media (max-width: 475px) {
            // margin: 0 20px 0 20px;
            // justify-content: center;
            padding-left: 20px;
            grid-gap: 40px !important;
            gap: 20px !important;
          }
        }

        .custom-increment-decrement {
          display: flex;
          align-items: center;
          background: transparent;
          border-radius: 30px;
          height: 50px;
          gap: 20px;

          @media (max-width: 475px) {
            gap: 10px;
          }

          &__quantity {
            width: 60px;
            height: 35px;
            background: var(--primary-color);
            color: #ffffff;
            display: flex;
            align-items: center;
            border-radius: 5px;
            justify-content: center;

            @media (max-width: 475px) {
              width: 40px;
              height: 25px;
            }

            p {
              font-size: 18px;
              font-weight: 900;
              line-height: 23px;

              margin: 0;
            }
          }

          &__decrement {
            width: 20px;
            height: 20px;
            color: #ffffff;
            border-radius: 50%;
            border: 2px solid rgb(118, 118, 118);
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #000000;

            @media (max-width: 475px) {
              width: 22px;
              height: 22px;
            }

            span:active {
              //background-color: yellow;
            }
          }

          &__increment {
            width: 20px;
            height: 20px;
            color: #2e2e2e;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            background: #ffffff;
            border: 2px solid rgb(118, 118, 118);

            @media (max-width: 475px) {
              width: 22px;
              height: 22px;
            }
          }
        }

        .item-modal-add-item {
          width: calc(65%);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          background: var(--primary-color);
          height: 50px;
          border-radius: 35px;
          padding: 0 20px 0 20px;
          flex-direction: row;
          justify-content: center;

          @media (max-width: 475px) {
            width: calc(100%);
            height: 40px;
            align-items: center;
            gap: 2px;
            //padding: 5px 0 40px 0;
            flex-direction: column;
            border-radius: 40px;
            margin-top: 4px;
          }

          h2 {
            margin: 0;

            &:nth-of-type(1) {
              font-size: 18px;

              line-height: 16px;
              color: #ffffff;
              font-weight: 900;

              @media (max-width: 1500px) {
                font-size: 18px;

                color: #ffffff;
                font-weight: 900;
              }

              @media (max-width: 475px) {
                font-size: 16px;

                color: #ffffff;
                font-weight: 900;
              }

              @media (max-width: 375px) {
                font-size: 20px;

                color: #ffffff;
                font-weight: 900;
              }
            }

            &:nth-of-type(2) {
              font-size: 18px;
              font-weight: 900;

              line-height: 18px;
              color: #ffffff;

              @media (max-width: 1500px) {
                font-size: 18px;

                color: #ffffff;
                font-weight: 900;
              }

              @media (max-width: 475px) {
                font-size: 16px;

                font-weight: 900;
              }

              @media (max-width: 375px) {
                font-size: 20px;

                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }

  .container-fluid {
    padding: 0;
    margin: 0;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 20px;

      @media (max-width: 475px) {
        justify-content: space-between;
      }

      @media (max-width: 414px) {
        justify-content: unset !important;

        .item-modal-add-item {
          h2 {
            .font-color-white {
              padding-left: 0 !important;
            }
          }
        }
      }

      @media (max-width: 350px) {
        column-gap: 20px;
      }

      @media (max-width: 399px) {
        justify-content: unset !important;
        column-gap: 20px;

        .item-modal-add-item {
          width: 60%;
          column-gap: 2px;
        }
      }

      @media (max-width: 392px) {
        justify-content: unset !important;
        column-gap: 20px;

        .item-modal-add-item {
          width: 61%;
        }
      }

      .add-order {
        display: flex;
        gap: 50px;

        @media (max-width: 475px) {
          // margin: 0 20px 0 20px;
          // justify-content: center;
          padding-left: 20px;
          grid-gap: 40px !important;
          gap: 20px !important;
        }
      }

      .custom-increment-decrement {
        display: flex;
        align-items: center;
        background: transparent;
        border-radius: 30px;
        height: 50px;
        gap: 20px;

        @media (max-width: 475px) {
          gap: 10px;
        }

        &__quantity {
          width: 60px;
          height: 35px;
          background: var(--button-color);
          color: #ffffff;
          display: flex;
          align-items: center;
          border-radius: 5px;
          justify-content: center;

          @media (max-width: 475px) {
            width: 40px;
            height: 25px;
          }

          p {
            font-size: 18px;
            font-weight: 900;
            line-height: 23px;

            margin: 0;
          }
        }

        &__decrement {
          width: 20px;
          height: 20px;
          color: #ffffff;
          border-radius: 50%;
          border: 2px solid rgb(118, 118, 118);
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: #000000;

          @media (max-width: 475px) {
            width: 22px;
            height: 22px;
          }

          span:active {
            //background-color: yellow;
          }
        }

        &__increment {
          width: 20px;
          height: 20px;
          color: #2e2e2e;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          background: #ffffff;
          border: 2px solid rgb(118, 118, 118);

          @media (max-width: 475px) {
            width: 22px;
            height: 22px;
          }
        }
      }

      .item-modal-add-item {
        width: calc(65%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background: var(--primary-color);
        height: 50px;
        border-radius: 35px;
        padding: 0 20px 0 20px;
        flex-direction: row;
        justify-content: center;

        @media (max-width: 475px) {
          width: calc(100%);
          height: 40px;
          align-items: center;
          gap: 2px;
          //padding: 5px 0 40px 0;
          flex-direction: column;
          border-radius: 40px;
          margin-top: 4px;
        }

        h2 {
          margin: 0;

          &:nth-of-type(1) {
            font-size: 18px;

            line-height: 16px;
            color: #ffffff;
            font-weight: 900;

            @media (max-width: 1500px) {
              font-size: 18px;

              color: #ffffff;
              font-weight: 900;
            }

            @media (max-width: 475px) {
              font-size: 16px;

              color: #ffffff;
              font-weight: 900;
            }

            @media (max-width: 375px) {
              font-size: 20px;

              color: #ffffff;
              font-weight: 900;
            }
          }

          &:nth-of-type(2) {
            font-size: 18px;
            font-weight: 900;

            line-height: 18px;
            color: #ffffff;

            @media (max-width: 1500px) {
              font-size: 18px;

              color: #ffffff;
              font-weight: 900;
            }

            @media (max-width: 475px) {
              font-size: 16px;

              font-weight: 900;
            }

            @media (max-width: 375px) {
              font-size: 20px;

              font-weight: 900;
            }
          }
        }
      }
    }
  }
}

.active-icon-class {
  &:before {
    color: var(--highlight-text-color) !important;
  }
}

.item-unavailable-modifier-modal {
  //padding: 0 !important;

  .container-fluid {
    width: 100%;
    margin: 0 !important;

    .row {
      margin: 0 !important;

      button {
        height: 60px;
        width: 100% !important;
        max-width: 100% !important;
        padding: 0;
      }
    }
  }
}

@-webkit-keyframes rodal-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes rodal-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

.show {
  -webkit-animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5) !important;
  animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5) !important;
}

.modal-enter .modalContent {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active .modalContent {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s;
}

.modal-exit .modalContent {
  opacity: 1;
}

.modal-exit-active .modalContent {
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.2s;
}

.popup-otp-input-section {
  .justify-content-center {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-section {
      margin-top: 30px;

      h4 {
        font-size: 14px;

        margin: 0;
        line-height: 18px;

        &:nth-of-type(1) {
          font-weight: 300;
          color: #6e6e6e;
        }

        &:nth-of-type(2) {
          font-weight: 400;
          color: #2e2e2e;
        }
      }
    }
  }
}

.name {
  @media (max-width: 500px) {
    //margin-right: auto;
  }
}

.from {
  @media (max-width: 500px) {
    margin-right: 12px;
    width: fit-content;
    padding: 0;
  }
}

.timing {
  margin-left: 9px;
}

.dropdown {
  z-index: 10 !important;
}

.modal-font-weight {
  font-weight: bold !important;
}

.item-price-column-space {
  grid-column-gap: 3px !important;
  column-gap: 3px !important;
}

.row-width-cart {
  width: 105%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 1100;
  transition: all 1s;
}

@media (max-width: 425px) {
  .card-scroll {
    overflow: hidden !important;
  }
}

.search-bar-width {
  width: 92% !important;
  z-index: 1050 !important;
  padding-left: 56px !important;
}

@media (min-width: 992px) {
  .item-detail-modal {
    display: flex !important;
    align-items: center;
    max-width: 900px !important;
    min-height: calc(100%);
  }

  .item-detail-modal-no-img {
    max-width: 600px !important;
    display: flex !important;
    align-items: center;
    min-height: calc(100%);
  }

  .col-display {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }

  .item-detail-modal {
    .modal-content {
      max-height: 450px !important;
      height: auto !important;
    }
  }

  .order-pref-modal {
    display: flex !important;
  }

  .route-pref-modal {
    .modal-content {
      height: auto !important;
    }
  }

  .modal-scroll {
    max-height: 100%;
    overflow-y: auto;
  }

  .item-detail-modal .container-fluid {
    padding: 10px !important;
  }

  .carousel .slide img {
    height: 450px !important;
  }

  .item-detail-modal .modal-body {
    border-radius: 30px !important;
  }

  .item-detail-modal .modal-content {
    border-radius: 30px !important;
  }

  .add-to-cart-shadow {
    box-shadow: 0px -3px 20px 0 rgb(0 0 0 / 20%);
  }

  .item-rating {
    position: absolute;
    top: 30px;
    left: 20px;
  }

  .item-price-modal {
    padding: 0px 20px 20px 20px !important;
  }

  .item-detail-modal .modal-content .close {
    box-shadow: 0px -3px 20px 0 rgb(0 0 0 / 20%);
  }

  .item-detail-modal .container-fluid .row .item-modal-add-item h2 {
    font-size: 14px !important;
  }

  .form-control-sm {
    font-size: 0.775rem !important;
  }
}

.category-bg-height {
  width: 100%;
  background: #f9f9f9;
  height: 20px;
  position: absolute;
}

.custom-deals-name {
  font-size: 20px;
  // font-weight: 500;

  line-height: 23px;
  position: relative;
  text-transform: uppercase;
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 0 !important;

  &:before {
    position: absolute;
    content: '';
    background: var(--primary-color);
    width: 2px;
    left: 0;
    height: calc(100% - 60px);
  }
}

.whatsapp-color-bg {
  background-color: #38a169;
}
.primary-color {
  background-color: var(--primary-color) !important;
}
.primary-font-color {
  color: var(--primary-font-color) !important;
}
.primary-error-font-color {
  color: var(--primary-error-color) !important;
}
.highlight-color-active {
  background-color: var(--highlight-color) !important;
  color: var(--highlight-text-color) !important;
}
.highlight-color-active-outline {
  background-color: var(--highlight-color) !important;
  color: var(--highlight-text-color) !important;
  border: 1px solid var(--highlight-text-color) !important;
}
.btn-color {
  background-color: var(--button-color) !important;
}
.branding-btn {
  background-color: var(--button-color) !important;
  color: var(--button-font-color) !important;
  border: unset !important;
}

.branding-btn-slider {
  color: var(--button-outline-color) !important;
  border: 3px solid var(--button-outline-color) !important;
}

.branding-btn-simple {
  color: var(--button-outline-color) !important;
  border: 1px solid var(--button-outline-color) !important;
}

.branding-btn-simple:hover {
  background-color: var(--button-color) !important;
  color: var(--button-font-color) !important;
  border: 1px solid var(--button-outline-color) !important;
}

.branding-btn-outline {
  background-color: var(--button-color) !important;
  color: var(--button-font-color) !important;
  border: 1px solid var(--button-outline-color) !important;
}
.branding-btn-font-color {
  color: var(--button-font-color) !important;
}
.branding-btn-color {
  color: var(--button-color) !important;
}
.highlight-color {
  color: var(--highlight-color) !important;
}
.highlight-text-color {
  color: var(--highlight-text-color) !important;
}

.highlight-pill-border {
  background-color: var(--highlight-color) !important;
  color: var(--highlight-text-color) !important;
  border: 1px solid var(--highlight-text-color) !important;
}

.card-outline-color {
  border: 1px solid var(--card-outline-color) !important;
}
.card-outline-color-hide {
  border: unset !important;
}
.card-error-color {
  color: var(--card-error-color) !important;
}
.header-bg {
  background: var(--header-bg-color) !important;
}

.branding-border-bottom {
  border-bottom: 1px solid var(--highlight-color) !important;
}

.header-font-1 {
  color: var(--header-font1-color) !important;
}

.card-color {
  background: var(--card-color) !important;
}

.card-font-color {
  color: var(--card-font-color) !important;
}

.card-subtitle-color {
  color: var(--card-subtitle-color) !important;
}

.card-divider-color {
  border-left: 0.5px solid var(--card-subtitle-color) !important;
}

.modal-content {
  background: var(--primary-color) !important;
  //   border: 1px solid var(--card-outline-color) !important;
}

.react-datepicker__time-list {
  background: var(--primary-color) !important;
}

.react-datepicker__time {
  background-color: var(--primary-color) !important;
}
.announcement_bg {
  background-color: var(--announcement-bg) !important;
}
.announcement_text_color {
  color: var(--announcement-text-color) !important;
}
.footer-bg {
  background-color: var(--footer-bg-color) !important;
}
.footer-text-color {
  color: var(--footer-text-color) !important;
}
.form-check-input:checked {
  background-color: var(--button-color) !important;
  border: 1px solid var(--card-outline-color) !important;
}
.item-modal-add-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: var(--primary-color);
  height: 35px;
  border-radius: 35px;
  padding: 0 10px 0 10px;
  flex-direction: row;
  justify-content: center;
}
.list-add-item {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
  border-radius: 35px;
  padding: 0 10px 2px 10px;
  flex-direction: row;
}
.list-add-btn {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  height: 35px;
  border-radius: 35px;
  padding: 0px 4px 18px 0px;
  flex-direction: row;
}
.decrement {
  width: 25px;
  height: 25px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid rgb(118, 118, 118);
  background: var(--button-font-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 26px;
  color: var(--button-color) !important;
}
.increment {
  width: 25px;
  height: 25px;
  color: var(--button-color) !important;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 26px;
  cursor: pointer;
  background: var(--button-font-color) !important;
  border: 1px solid rgb(118, 118, 118);
}
.list-btn-increment {
  width: 25px;
  height: 25px;
  color: var(--button-font-color) !important;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 26px;
  cursor: pointer;
  background: var(--button-color) !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
.list-btn-decrement {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--button-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 26px;
  color: var(--button-font-color) !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
.list-input {
  background: white;
  width: 40px;
  text-align: center;
  font-size: 15px;
  margin: 2px;
  border: 1px solid var(--button-color) !important;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
.list-item-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 32px;
  margin: 2px;
  background: var(--button-color) !important;
  color: var(--button-font-color) !important;
  border-radius: 20px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
.title-height {
  height: 38px;
}
.description-height {
  height: 33px;
}
.box-shadow {
  box-shadow: 0 9px 20px rgba(0, 0, 0, 0.06);
}
.show {
  -webkit-animation: none !important;
  animation: none both !important;
}
.lottie-svg-class {
  width: 50% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.hovernowshodow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
.modal-backdrop {
  backdrop-filter: blur(10px);
  background-color: transparent !important;
  opacity: 1 !important;
}
// .modal-backdrop {
//   backdrop-filter: blur(5px);
//   background-color: transparent !important;
//   opacity: 1 !important;
// }
.modal-footer {
  border-top: 0px !important;
}
.dot {
  height: 150px;
  width: 150px;
  background-color: #277f00;
  border-radius: 50%;
  display: inline-block;
}
.check-font-size {
  font-size: 4rem !important;
}
@media (max-width: 1024px) {
  .dot {
    height: 100px;
    width: 100px;
  }
  .check-font-size {
    font-size: 2rem !important;
  }
}
@media (max-width: 820px) {
  .dot {
    height: 100px;
    width: 100px;
  }
  .check-font-size {
    font-size: 2rem !important;
  }
}
@media (max-width: 768px) {
  .dot {
    height: 100px;
    width: 100px;
  }
  .check-font-size {
    font-size: 2rem !important;
  }
}
@media (max-width: 500px) {
  .dot {
    height: 100px;
    width: 100px;
  }
  .check-font-size {
    font-size: 2rem !important;
  }
}
.footer-padding-left {
  padding-left: 55px !important;
}
@media (max-width: 500px) {
  .footer-padding-left {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }
}

.footer-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  opacity: 0.8;
}
.footer-z-index {
  z-index: 5 !important;
}
.hovernow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
  transition: box-shadow 0.3s ease-in-out;
  height: 100%;
}
.bannerborder {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
  transition: box-shadow 0.3s ease-in-out;
  height: 80px !important;
}
.menu-cart-list-ecommerce {
  max-height: 250px !important;
}
.video-banner {
  width: 100%;
  max-height: 700px;
  object-fit: cover;
  margin-bottom: -8px;
}
.swiper-slide-mobile {
  @media (max-width: 768px) {
    //margin-right: 75px !important;
    padding-top: calc(500 / 1280 * 280%) !important;
  }
}
.bin-card {
  border: 0px !important;
  border-radius: 23px !important;
}
.card-text-size {
  font-size: 14px !important;
}
.bin-start-ft-size {
  font-size: 16px !important;
}
.card-text-size-specific {
  font-size: 15px !important;
  @media (max-width: 430px) {
    font-size: 13px !important;
  }
  @media (max-width: 414px) {
    font-size: 11px !important;
  }
}
.card-icon-width {
  width: 28px !important;
}
.swiper-slide-mobile-defualt {
  @media (max-width: 768px) {
    //margin-right: 75px !important;
    padding-top: calc(500 / 1280 * 130%) !important;
  }
}
.item-modal-reward {
  width: calc(100%) !important;
}
.cart-list-button-reward {
  width: 20% !important;
}
.options-data__right-side-reward {
  width: 20px !important;
}
.actions-reward {
  justify-content: center !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.reward-text-bg-red {
  font-weight: 300;
  font-family: Larsseit, sans-serif;
  font-size: 12px;
  color: #ffffff !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  background-color: var(--primary-color);
}
.reward-text-bg-green {
  font-weight: 300;
  font-family: Larsseit, sans-serif;
  font-size: 12px;
  color: #ffffff !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  background-color: #198754 !important;
}
.earned-reward-items {
  flex-basis: 198px !important;
}
.loyalty-card-width {
  width: 500px !important;
}
.loyalty-items {
  flex-basis: 30% !important;
}

@media (max-width: 1200px) {
  .loyalty-items {
    flex-basis: 48% !important;
  }
}

@media (max-width: 968px) {
  .loyalty-items {
    flex-basis: 42% !important;
  }
}

@media (max-width: 768px) {
  .loyalty-items {
    flex-basis: 100% !important;
  }
}
// @media (max-width: 1024px) {
//   .loyalty-card-width {
//     width: 350px !important;
//   }
//   .loyalty-items {
//     flex-basis: 350px !important;
//   }
// }

.custom-circle {
  width: 45px;
  height: 45px;
  background-color: var(--highlight-text-color);
  border: 2px solid var(--highlight-color);
  border-radius: 50%;
}
.custom-circle-check {
  width: 45px;
  height: 45px;
  background-color: var(--highlight-color);
  border: 2px solid var(--highlight-color);
  border-radius: 50%;
}
.loyalty-card-hover {
  height: auto !important;
}
.vertical-line {
  border-left: 2px solid var(--highlight-color);
  height: 20px; /* Adjust height as needed */
  margin-left: 1.3rem !important;
}
.circular-progress-alignment {
  margin-right: 1rem !important;
  @media (max-width: 1400px) {
    margin-right: 0.6rem !important;
  }
}
.loyalty-menu-btn-font {
  font-size: 8px !important;
  @media (max-width: 1200px) {
    font-size: 6px !important;
  }
}
.loyalty-progress-text {
  font-size: 12px;
  margin-top: -5px;
}
@media (max-width: 1280px) {
  .loyalty-menu-btn-font {
    font-size: 6px !important;
  }
}

@media (max-width: 1024px) {
  .loyalty-menu-btn-font {
    font-size: 6px !important;
  }
}

@media (max-width: 768px) {
  .loyalty-menu-btn-font {
    font-size: 5px !important;
  }
}
@media (max-width: 500px) {
  .loyalty-menu-btn-font {
    font-size: 10px !important;
  }
}

@media (max-width: 344px) {
  .loyalty-menu-btn-font {
    font-size: 8px !important;
  }
}

@media (max-width: 1366px) {
  .loyalty-progress-text {
    font-size: 12px;
    margin-top: -5px;
  }
}
@media (max-width: 1280px) {
  .loyalty-progress-text {
    font-size: 10px;
    margin-top: -5px;
  }
}
@media (max-width: 1100px) {
  .loyalty-progress-text {
    font-size: 9px;
    margin-top: -5px;
  }
}
@media (max-width: 768px) {
  .loyalty-progress-text {
    font-size: 8px;
    margin-top: -5px;
  }
}
@media (max-width: 500px) {
  .loyalty-progress-text {
    font-size: 16px;
    margin-top: -5px;
  }
}
@media (max-width: 400px) {
  .loyalty-progress-text {
    font-size: 11px;
    margin-top: -5px;
  }
}
@media (max-width: 430px) {
  .loyalty-progress-text {
    font-size: 13px;
    margin-top: -5px;
  }
}
@media (max-width: 1024px) {
  .cart-sidebar-menu-data {
    width: 326px !important;
  }
}

.cart-sidebar-menu-data {
  position: fixed;
  width: 400px;
  background: #ffffff;
  box-shadow: -3px 20px 20px rgba(0, 0, 0, 0.06);
  height: 100vh;
  top: 0;
  right: -100%;
  padding: 20px;
  list-style-type: none;
  transition: all 0.35s ease-in-out;
  z-index: 1250;
}

.cart-reward-order {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  color: #2e2e2e;
  margin: 0;
  position: relative;
}
.line-item-container-custom .line-item .quantity {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  width: 22px;
  height: 20px;
  background: #0cb;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  margin-right: 10px;
}
.reward-cart-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
}
.cart-badge {
  transform: translate(-32%, -73%) !important;
}
.loyalty-row-action-width {
  width: 60px;
}
.card_layout_custom-no-width {
  width: 100% !important;
}
.loyalty-menu-title {
  position: absolute;
  color: white;
  font-size: 10px;
  bottom: 5px;
  left: 11px;
  text-decoration: underline !important;
  cursor: pointer;
}
.loyalty-item-text-width {
  width: 250px;
}
.loyalty-circle-width {
  width: 56px;
}
.loyalty-item-text-size {
  font-size: 13px;
  @media (max-width: 400px) {
    font-size: 11px;
  }
  @media (min-width: 820px) and (max-width: 1300px) {
    font-size: 11px;
  }
}
.loyalty-complete-img {
  border-radius: 50px !important;
  height: 100px !important;
  width: 100px !important;
  position: relative !important;
}
.page-container {
  min-height: calc(50vh);
}
.btn-slider {
  width: 300px;
  @media (max-width: 768px) {
    width: auto !important;
    max-width: 200px;
  }
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 1px 1px #000;
  color: #fff !important;
  border: 2px solid #fff !important;
  box-shadow: 0 0 20px 1px #000;
  font-weight: bold !important;
}
.modal-header {
  justify-content: space-between;
}
.custom-datepicker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.asap-option {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 8px; /* Space before datepicker */
}

.asap-option.selected {
  background-color: var(--highlight-color); /* Green background when selected */
  color: var(--highlight-text-color) !important;
}

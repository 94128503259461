$primary-color: var(--primary-color);

.radio-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.radio-tile-group {
  display: flex;

  width: 100%;

  //set dimensions for invisible container
  .input-container {
    position: relative;
    height: 5rem;

    &.order-type {
      // width: 23.5%;
      margin: 1%;
    }

    &.payment-option {
      width: 48%;
      margin: 2%;
    }

    &.payment-option-single {
      width: 100% !important;
      margin-left: 20px !important;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    //make actual radio input invisible
    // + stretch to fill container
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 1px solid var(--highlight-color);
      border-radius: 5px;
      padding: 0.5rem;
      transition: transform 300ms ease;
    }
    .icon svg {
      color: var(--highlight-color);
      width: 1.5rem;
      height: 1.5rem;
    }
    .radio-tile-label {
      text-align: center;
      font-size: 0.75rem;
      @media (max-width: 414px) {
        font-size: 0.6rem !important;
      }

      @media (max-width: 360px) {
        font-size: 0.5rem !important;
      }

      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--card-font-color);
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: var(--highlight-color);
      border: 2px solid var(--highlight-color);
      color: var(--highlight-text-color);
      transform: scale(1.1, 1.1);

      .icon svg {
        color: var(--highlight-text-color);
        background-color: var(--highlight-color);
      }
      .radio-tile-label {
        color: var(--highlight-text-color);
        background-color: var(--highlight-color);
      }
    }
  }
}

:root {
  --primary-color: #0cb;
  --highlight-color: #0cb;
}

$primary: #0cb;
$success: #92d14f;
$danger: #f92827;

$max-width: 100%;
$min-height: 100vh;
